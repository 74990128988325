/* eslint-disable react/require-default-props */
import Typography from '@mui/material/Typography';
import { Button, Skeleton, capitalize } from '@mui/material';
import { SetStateAction } from 'react';
import type Stripe from 'stripe';

type Props = {
  paymentMethod?: Stripe.PaymentMethod;
  setNeedsPaymentMethod: React.Dispatch<SetStateAction<boolean>>;
};

export default function PaymentMethods({
  paymentMethod,
  setNeedsPaymentMethod,
}: Props) {
  const cardBrand = paymentMethod?.card?.brand
    ? capitalize(paymentMethod?.card.brand)
    : 'Credit card';

  return paymentMethod ? (
    <div className="payment-method">
      <Typography variant="h4">Payment information:</Typography>
      <div className="card-details">
        <img src="/img/lock.png" alt="secure payment" />
        <Typography variant="body2">
          <strong>{cardBrand}</strong> ending in{' '}
          <strong>{paymentMethod.card?.last4}</strong>
        </Typography>
        <Button
          variant="text"
          className="change-payment"
          onClick={() => {
            setNeedsPaymentMethod(true);
          }}>
          Add New Card
        </Button>
      </div>
    </div>
  ) : (
    <Skeleton variant="rectangular" height={120} />
  );
}
