import { useQuery } from '@tanstack/react-query';
import { getEmployeeByUrlPath, getEmployeeById } from '../../services/employee';
import { Employee } from '../../interfaces';

function useGetEmployee({
  employeeId,
  path,
  onSuccess,
}: {
  employeeId?: string;
  path?: string;
  onSuccess?: (employee: Employee) => void;
}) {
  const enabled = !!path || !!employeeId;

  const query = useQuery({
    queryKey: ['employee', path, employeeId],
    queryFn: async () => {
      const getEmployee = path ? getEmployeeByUrlPath : getEmployeeById;

      const tempEmployee = await getEmployee(path ?? employeeId!);

      if (tempEmployee) {
        onSuccess?.(tempEmployee);
      }

      return tempEmployee || null;
    },
    enabled,
  });

  return {
    employee: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
}

export default useGetEmployee;
