/* eslint-disable no-param-reassign */
import baseAxios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getCurrentUserAuthorization } from './global';
import API_URL from '../apiconfig';

const axios: AxiosInstance = baseAxios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json',
  },
});

const onRequest = async (config: AxiosRequestConfig): Promise<any> => {
  const authorization = await getCurrentUserAuthorization();
  if (config.headers) {
    if (authorization) {
      config.headers.Authorization = authorization;
    }
    config.headers['x-porter-source'] = 'customer-web';
  }
  return config;
};

axios.interceptors.request.use(onRequest);

export type { AxiosResponse } from 'axios';
export { isAxiosError } from 'axios';

export default axios;
