import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

const validationSchema = yup.object({
  inspiration: yup.string().required('Tattoo inspiration is required'),
});

function Inspiration({ formValues, setFormValues, nextStep, prevStep }: Props) {
  const questionValues = {
    header: "What's the inspiration for your tattoo?",
    subHeader:
      'Knowing what the tattoo means to you will help create the best possible designs',
  };

  const formik = useFormik({
    initialValues: {
      inspiration: formValues.inspiration,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('inspiration', values.inspiration!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="tattoo-inspiration">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        <TextField
          id="inspiration"
          name="inspiration"
          value={formik.values.inspiration}
          onChange={formik.handleChange}
          error={
            formik.touched.inspiration && Boolean(formik.errors.inspiration)
          }
          helperText={formik.touched.inspiration && formik.errors.inspiration}
          multiline
          placeholder="What inspires you to want this tattoo? What's your story behind it? Why does it have meaning to you?"
          minRows={3}
          className="textarea"
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default Inspiration;
