import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  name: string;
  handleClose: () => void;
};

export default function ModalHeader({ name, handleClose }: Props) {
  return (
    <div className="modal-header-confirmation">
      <button className="close-modal" type="button" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </button>
      <Typography variant="h2" align="center" style={{ width: '100%' }}>
        {name}
      </Typography>
    </div>
  );
}
