import { Typography } from '@mui/material';

interface Props {
  label: string;
  amount: string;
}

export default function PaymentTableRow({ label, amount }: Props) {
  let className: string = '';
  if (label === 'Tattoo Price') {
    className = 'tattoo-price';
  }
  if (label === 'Deposit Fee (paid to artist)') {
    className = 'deposit-amount';
  }
  if (label === 'Service Fee (~3.5%)') {
    className = 'service-fee';
  }
  return (
    <div className="payment-row">
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body2" className={className}>
        {amount}
      </Typography>
    </div>
  );
}
