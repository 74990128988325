import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
  Path,
  Font,
  Link,
} from '@react-pdf/renderer';
import { Html } from 'react-pdf-html';
import type { Style } from '@react-pdf/types';
import { format } from 'date-fns-tz';
import { FullWaiver, WaiverForm } from '../../../types/waivers';
import { formatPhone } from '../../../utils/global';
import { Appointment, Business } from '../../../interfaces';
import { SIGNATURE_ATTESTATION } from '../../../constants/waivers';
import { getDefaultStaticLiabilityText } from '../../../utils/waivers';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf',
      fontWeight: 'semibold',
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf',
      fontWeight: 'bold',
    },
  ],
});

const PAGE_VERTICAL_PADDING = 32;

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Montserrat',
    flexDirection: 'column',
    gap: 32,
    backgroundColor: '#FFFFFF',
    paddingVertical: PAGE_VERTICAL_PADDING,
  },
  section: {
    paddingHorizontal: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  header: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
    fontSize: 24,
  },
  sectionHeader: {
    textAlign: 'center',
    width: '100%',
    fontWeight: 'semibold',
    fontSize: 18,
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingRight: 32,
  },
  checkbox: {
    width: 14,
    height: 14,
    aspectRatio: 1,
    borderRadius: 2,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#000',
    marginRight: 5,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  checkmark: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: -1,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacer: {
    height: 10,
  },
  text: {
    fontSize: 12,
  },
  textField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  textFieldInput: {
    border: '0.5px solid #000',
    padding: 5,
    width: '100%',
    borderRadius: 1,
    minHeight: 150,
  },
  idSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
  },
  footerText: {
    fontSize: 8,
    textAlign: 'center',
    textDecoration: 'none',
    color: '#333',
  },
});

function Section({
  header,
  title,
  children,
  style,
  wrap = true,
}: React.PropsWithChildren<{
  header?: string;
  title?: string;
  style?: Style;
  wrap?: boolean;
}>) {
  return (
    <View style={{ ...styles.section, ...style }} wrap={wrap}>
      {header && (
        <Text style={styles.sectionHeader} wrap={false}>
          {header}
        </Text>
      )}
      {title && (
        <Text style={styles.text} wrap={false}>
          {title}
        </Text>
      )}
      {children}
    </View>
  );
}

function Checkbox({ checked, label }: { checked: boolean; label: string }) {
  return (
    <View style={[styles.checkboxContainer]} wrap={false}>
      <View
        style={[
          styles.checkbox,
          {
            borderColor: checked ? '#333' : '#000',
            backgroundColor: checked ? '#333' : 'transparent',
          },
        ]}>
        {checked && (
          <Svg viewBox="0 0 24 24" style={styles.checkmark}>
            <Path
              d="M20.285 4.285a1.003 1.003 0 00-1.415 0L9 14.15l-3.87-3.87a1.003 1.003 0 00-1.415 1.415l4.585 4.585a1.003 1.003 0 001.415 0l10.585-10.585a1.003 1.003 0 000-1.415z"
              fill={checked ? '#fff' : '#000'}
            />
          </Svg>
        )}
      </View>
      <Text style={styles.text}>{label}</Text>
    </View>
  );
}

function TextField({ label, value }: { label: string; value: string }) {
  return (
    <View style={styles.textField} wrap={false}>
      <Text style={styles.text} wrap={false}>
        {label}
      </Text>
      <Text style={{ ...styles.text, ...styles.textFieldInput }}>{value}</Text>
    </View>
  );
}

interface Props {
  appointment: Appointment;
  waiverForm: WaiverForm;
  waiver: FullWaiver;
  business: Business;
  idBuffer?: Buffer;
  staticWaiverImages?: string[];
}

function WaiverPDF({
  appointment,
  waiverForm,
  waiver,
  business,
  idBuffer,
  staticWaiverImages,
}: Props) {
  const isStaticWaiverEnabled = !!waiver.staticWaiverSettings?.enabled;
  const isAcknowledgmentsEnabled = !!waiver.acknowledgmentSettings?.enabled;
  const isMedicalHistoryEnabled = !!waiver.medicalHistorySettings?.enabled;
  const isIDUploadEnabled = !!waiver.identificationSettings?.enabled;

  const acknowledgmentOptions = waiver.acknowledgmentSettings?.options || [];
  const medicalHistoryCheckboxOptions = (
    waiver.medicalHistorySettings?.options || []
  ).filter(o => o.type === 'CHECKBOX');
  const medicalHistoryTextOptions = (
    waiver.medicalHistorySettings?.options || []
  ).filter(o => o.type === 'TEXT');

  return (
    <Document>
      {isStaticWaiverEnabled && !!staticWaiverImages?.length && (
        <Page size="A4">
          {staticWaiverImages.map(url => (
            <Image
              key={url}
              src={url}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          ))}
        </Page>
      )}
      <Page size="A4" style={styles.page}>
        <View fixed style={styles.footer}>
          <Link style={styles.footerText} href="https://getporter.io">
            Powered by Porter
          </Link>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>
            {business.name || 'Waiver for Appointment'}
          </Text>
        </View>
        <View style={{ ...styles.section, gap: 5, justifyContent: 'flex-end' }}>
          <Text style={styles.text} wrap={false}>
            Name:{' '}
            {`${waiverForm.customerDetails.firstName || ''} ${
              waiverForm.customerDetails.lastName || ''
            }`.trim()}
          </Text>
          <Text style={styles.text} wrap={false}>
            Email: {waiverForm.customerDetails.email}
          </Text>
          <Text style={styles.text} wrap={false}>
            Phone Number: {formatPhone(waiverForm.customerDetails.phoneNumber)}
          </Text>
          <Text style={styles.text} wrap={false}>
            Date of Birth:{' '}
            {format(
              new Date(
                `${waiverForm.customerDetails.dob.split('T')[0]}T00:00:00`,
              ),
              'MM/dd/yyyy',
            )}
          </Text>
          <Text style={styles.text} wrap={false}>
            {/* include timezone */}
            Signed On: {format(new Date(), 'MM/dd/yyyy h:mm a zzz')}
          </Text>
        </View>

        {isStaticWaiverEnabled && (
          <Section header="Waiver">
            {!waiver.staticWaiverSettings?.documentLink && (
              <Html
                collapse={false}
                resetStyles
                stylesheet={{
                  '.bold': {
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                  },
                }}
                style={styles.text}>
                {getDefaultStaticLiabilityText({
                  employee: {
                    firstName: appointment.employee?.firstName || '',
                    lastName: appointment.employee?.lastName || '',
                  },
                  business,
                }).replaceAll('&emsp;', '  ')}
              </Html>
            )}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
              wrap={false}>
              <Text style={styles.text}>
                {waiver.staticWaiverSettings?.attestationText || ''}
              </Text>
              <Image
                src={waiverForm.staticLiabilitySignature}
                style={{ width: 100, height: 100, objectFit: 'contain' }}
              />
            </View>
          </Section>
        )}

        {isAcknowledgmentsEnabled && (
          <Section
            header="Acknowledgments"
            title={waiver.acknowledgmentSettings?.intro || ''}>
            {acknowledgmentOptions.map(option => (
              <Checkbox
                key={option.id}
                label={option.text}
                checked={waiverForm.acknowledgments[option.id]}
              />
            ))}
          </Section>
        )}

        {isMedicalHistoryEnabled && (
          <Section
            header="Medical History"
            title={waiver.medicalHistorySettings?.intro || ''}>
            {/* map through checkbox options in waiver with corresponding user response from waiverForm */}
            {medicalHistoryCheckboxOptions.map(option => (
              <Checkbox
                key={option.id}
                label={option.text}
                checked={!!waiverForm.medicalHistory[option.id]}
              />
            ))}
            {/* map through text options in waiver with corresponding user response from waiverForm */}
            {medicalHistoryTextOptions.map(option => (
              <TextField
                key={option.id}
                label={option.text}
                value={(waiverForm.medicalHistory[option.id] || '') as string}
              />
            ))}
          </Section>
        )}

        {isIDUploadEnabled && idBuffer && (
          <Section
            wrap={false}
            header="ID Upload"
            title={waiver?.identificationSettings?.text || ''}
            style={styles.idSection}>
            <Image
              src={idBuffer}
              style={{ width: '50%', height: 'auto', objectFit: 'contain' }}
            />
          </Section>
        )}

        <Section title={SIGNATURE_ATTESTATION} wrap={false}>
          <Image
            src={waiverForm.signature}
            style={{ width: 100, height: 100, objectFit: 'contain' }}
          />
        </Section>
      </Page>
    </Document>
  );
}

export default WaiverPDF;
