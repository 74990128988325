import { User } from 'firebase/auth';
import { VerifyChannel } from '../types/auth';
import axios from './api';
import API_URL from '../apiconfig';

export async function setAuthorization(authUser: User | null | undefined) {
  if (authUser) {
    const accessToken = await authUser.getIdToken();
    // console.log(accessToken);
    return `Bearer ${accessToken}`;
  }
  return false;
}

export async function sendVerifyMessage(
  phoneNumber: string,
  channel: VerifyChannel,
) {
  try {
    const response = await axios.post(`${API_URL}/v1/users/verify`, {
      phoneNumber,
      channel,
    });
    return response.data.result as boolean;
  } catch (error) {
    throw new Error('Failed to send verification message');
  }
}

export async function checkVerification(phoneNumber: string, code: string) {
  try {
    const response = await axios.post(`${API_URL}/v1/users/verify/check`, {
      phoneNumber,
      code,
    });
    return response.data.result as string;
  } catch (error) {
    throw new Error('Failed to validate verification code');
  }
}
