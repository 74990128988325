/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Form from '../components/Form/Form';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ProgressBar from '../components/EmployeeHeader/ProgressBar';
import useGetEmployee from '../hooks/employees/useGetEmployee';
import ServicesBookingList from '../components/Services/ServicesBookingList';
import EmployeeHeader from '../components/EmployeeHeader/EmployeeHeader';
import { DEFAULT_TATTOO_SERVICE_ID } from '../constants/global';
import LoadingIndicator from '../components/Global/LoadingIndicator';

export default function ArtistPage() {
  const navigate = useNavigate();
  const { urlPath } = useParams();

  const [progress, setProgress] = React.useState(
    sessionStorage.getItem('progress') || 'tattoo details',
  );
  const [step, setStep] = React.useState<number>(() =>
    JSON.parse(sessionStorage.getItem('step') || '1'),
  );

  const [askCity, setAskCity] = React.useState(false);
  const [appointmentCity, setAppointmentCity] = React.useState(
    sessionStorage.getItem('city') || '',
  );
  const [appointmentTimezone, setAppointmentTimezone] = React.useState(
    sessionStorage.getItem('timezone') || '',
  );
  const [tattooIsSelected, setTattooIsSelected] = React.useState(false);

  const { employee, isLoading, isError } = useGetEmployee({
    path: urlPath,
    onSuccess: tempEmployee => {
      window._cio.page('Artist', {
        displayName: tempEmployee.displayName,
      });

      if (
        tempEmployee.trips &&
        tempEmployee.trips.length > 0 &&
        tempEmployee.trips.some(trip => trip.booksOpen)
      ) {
        setAskCity(true);
      }
    },
  });

  const isBookable = employee?.isBookable;
  const hasServices = employee?.services && employee.services.length > 0;

  const handleServiceClick = (id: string | number) => {
    if (id === DEFAULT_TATTOO_SERVICE_ID) {
      setTattooIsSelected(true);
    } else {
      const service = employee?.services?.find(s => s.serviceId === id);
      if (!service?.bookingUrl) return;
      navigate(`${service.bookingUrl}?employeeId=${employee?.employeeId}`);
    }
  };

  if (isLoading) return <LoadingIndicator />;

  if (isError || !employee) {
    return (
      <Container maxWidth="lg" className="data-error">
        <Typography variant="h1">
          There was an error loading this artist&apos;s page. Please double
          check that you&apos;ve entered the url in correctly, and if the error
          persists, reach out to our support team using the help icon below.
        </Typography>
      </Container>
    );
  }

  const shouldShowForm = (isBookable && !hasServices) || tattooIsSelected;

  return (
    <Box
      style={{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
        backgroundColor:
          employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
            ? '#ebcbd2'
            : '#ffffff',
      }}>
      {shouldShowForm ? (
        <>
          <TopBar
            title={employee?.displayName || employee?.user.firstName}
            subtitle="Booking Request"
            step={step}
          />
          <ProgressBar
            progress={progress}
            step={step}
            intakeQuestions={employee?.intakeQuestions}
          />
          <Form
            progress={progress}
            setProgress={setProgress}
            step={step}
            setStep={setStep}
            employee={employee}
            appointmentCity={appointmentCity}
            setAppointmentCity={setAppointmentCity}
            appointmentTimezone={appointmentTimezone}
            setAppointmentTimezone={setAppointmentTimezone}
            askCity={askCity}
            intakeQuestions={employee?.intakeQuestions}
          />
        </>
      ) : hasServices ? (
        <Box sx={{ paddingTop: 5, paddingX: 2 }}>
          <EmployeeHeader employee={employee} business={employee?.business} />
          <ServicesBookingList
            header="Select a Service"
            subHeader={`Choose the service you want to book with ${
              employee?.displayName || 'this artist'
            } below.`}
            showTattoo={isBookable}
            onClick={handleServiceClick}
            services={employee?.services}
          />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            This artist is not currently accepting bookings. Please contact
            support.
          </Typography>
        </Container>
      )}

      <PorterFooter />
    </Box>
  );
}
