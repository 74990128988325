import Typography from '@mui/material/Typography';
import { ImageList, ImageListItem } from '@mui/material';

type Props = {
  label: string;
  values: string[];
  type: string;
};

export default function DetailRow({ label, values, type }: Props) {
  switch (type) {
    case 'image':
      return (
        <div className="review-item">
          <ImageList cols={3}>
            {values.map(value => (
              <ImageListItem key={value}>
                <img src={`${value}`} alt={value} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      );
    case 'text':
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          {values.map(value => (
            <Typography variant="body1" key={value}>
              {value}
            </Typography>
          ))}
        </div>
      );
    default:
      return (
        <div className="review-item">
          <Typography variant="h3">{label}</Typography>
          {values.map(value => (
            <Typography variant="body1" key={value}>
              {value}
            </Typography>
          ))}
        </div>
      );
  }
}
