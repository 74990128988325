/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Employee } from '../interfaces';
import { getEmployeeByUrlPath } from '../services/employee';
import TopImage from '../components/BusinessHeader/TopImage';
import { PLACES_API_KEY } from '../maps';
import FAQAccordion from '../components/Global/FaqAccordion';

export default function ProfilePage() {
  const { artistUrl } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [employee, setEmployee] = React.useState<Employee>();
  const [employeeError, setEmployeeError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [businessName, setBusinessName] = React.useState<string>();
  const [businessCityState, setBusinessCityState] = React.useState<string>();
  const [addressSearch, setAddressSearch] = React.useState<string>();

  React.useEffect(() => {
    async function fetchData() {
      const tempEmployee = await getEmployeeByUrlPath(artistUrl).catch(() => {
        setEmployeeError(true);
      });

      if (tempEmployee) {
        // eslint-disable-next-line no-underscore-dangle
        window._cio.page('Profile', {
          displayName: tempEmployee.displayName,
        });

        setEmployee({
          ...tempEmployee,
        });
      }

      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, artistUrl]);

  React.useEffect(() => {
    if (employee && employee.business) {
      const { business } = employee;
      setBusinessName(business.name);
      setBusinessCityState(`${business.city}, ${business.state}`);

      let tempAddress = '';
      if (business.streetAddress) {
        tempAddress += business.streetAddress;
      }
      if (tempAddress && business.streetAddress2) {
        tempAddress += `, ${business.streetAddress2}`;
      }
      tempAddress += `${business.city}, ${business.state} ${business.zip}`;
      setAddressSearch(tempAddress);
    }
  }, [employee]);

  return (
    <Container
      maxWidth="md"
      className="shop-page"
      style={{
        padding: 0,
        backgroundColor:
          employee?.employeeId === 'clix99ce5001ks60uygdf0xcr'
            ? '#ebcbd2'
            : employee?.employeeId === 'cln0oy4xc0000s60u3ntgnt2b'
            ? '#87CEEB'
            : '#ffffff',
      }}>
      {employee && (
        <Box>
          <TopImage images={employee?.images} />
          <Box
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              display: 'flex',
              flexDirection: 'column',
              gap: 20,
              marginTop: 20,
              marginBottom: 111,
            }}>
            <Box
              sx={{
                paddingBottom: '20px',
                borderBottom: 1,
                borderColor: 'lightGrey.main',
              }}>
              <Typography variant="h1">
                {employee?.displayName ||
                  `${employee?.user.firstName} ${employee?.user.lastName}`}
              </Typography>
              <Typography variant="body1">{businessName}</Typography>
              <Typography variant="body1">{businessCityState}</Typography>
              {employee.user.igHandle && (
                <a
                  href={employee.user.igHandle}
                  rel="noreferrer"
                  target="_blank"
                  style={{
                    float: 'left',
                    marginTop: 10,
                    color: theme.palette.darkGrey.main,
                  }}>
                  <InstagramIcon />
                </a>
              )}
            </Box>
            {employee?.about && (
              <Box
                sx={{
                  paddingBottom: '20px',
                  borderBottom: 1,
                  borderColor: 'lightGrey.main',
                }}>
                <Typography variant="h2">
                  About{' '}
                  {employee?.displayName ||
                    `${employee?.user.firstName} ${employee?.user.lastName}`}
                </Typography>
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                  {employee?.about}
                </Typography>
              </Box>
            )}
            {employee.faqs && employee.faqs.length > 0 && (
              <Box
                sx={{
                  paddingBottom: '20px',
                  borderBottom: 1,
                  borderColor: 'lightGrey.main',
                }}>
                <Typography variant="h2">FAQ</Typography>
                <FAQAccordion accordionItems={employee.faqs} />
              </Box>
            )}
            <Box
              sx={{
                paddingBottom: '20px',
                borderBottom: 1,
                borderColor: 'lightGrey.main',
              }}>
              <Typography variant="h2" style={{ marginBottom: 20 }}>
                Location
              </Typography>
              <div>
                <iframe
                  title="google-map-embed"
                  width="100%"
                  height="250"
                  frameBorder="0"
                  style={{ border: 0 }}
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=${PLACES_API_KEY}&q=${addressSearch}`}
                  allowFullScreen
                />
              </div>
            </Box>
          </Box>
          <div className="footer-sticky">
            <Button
              variant="contained"
              className="next done checkout"
              onClick={() => navigate(`/artist/${employee?.urlPath}`)}>
              Request to Book
            </Button>
            <Typography variant="body2" className="center">
              Powered by Porter
            </Typography>
          </div>
        </Box>
      )}
      {employeeError && (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this artist&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      )}
    </Container>
  );
}
