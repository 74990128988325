export default function useIOSVersion() {
  const ua = navigator.userAgent;
  if (/iP(hone|od|ad)/.test(ua)) {
    const match = ua.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match) {
      const majorVersion = parseInt(match[1], 10);
      const minorVersion = parseInt(match[2], 10);
      const patchVersion = parseInt(match[3] || '0', 10);
      return {
        major: majorVersion,
        minor: minorVersion,
        patch: patchVersion,
      };
    }
  }
  return null; // Not an iOS device
}
