import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionItem } from '../../interfaces';

interface Props {
  accordionItems: AccordionItem[];
}

export default function FAQAccordion({ accordionItems }: Props) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      {accordionItems.map((item, index) => (
        <Accordion
          expanded={expanded === `item-${index}`}
          onChange={handleChange(`item-${index}`)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`item-${index}bh-content`}
            id={`item-${index}bh-header`}>
            <Typography variant="body1">{item.header}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{item.text}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
