/* eslint-disable no-underscore-dangle */
import React, { useReducer, useEffect, useMemo, useCallback } from 'react';
import { useIdToken } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { getUser } from '../../services/user';
import { AppContext, AppState, AppStateAction, initialState } from './context';

const appReducer = (state: AppState, action: AppStateAction) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

interface AppProviderProps {
  children: React.ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  const [authUser, authLoading] = useIdToken(auth);
  const [state, dispatch] = useReducer(appReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]); // Dependencies array

  const getUserData = useCallback(async () => {
    if (authUser) {
      try {
        const tempUser = await getUser();

        if (tempUser.userId) {
          const createdAt = new Date(tempUser.createdAt);
          window.heap.identify(tempUser.userId);
          window._cio.identify({
            // Required attributes — you must include at least one of the following
            id: tempUser.userId, // Unique id for the user in your application.
            email: tempUser.email, // Email of the currently signed in user.

            created_at: Math.floor(createdAt.getTime() / 1000),
            first_name: tempUser.firstName,
            last_name: tempUser.lastName,
            phone_number: tempUser.phoneNumber,
          });
        }

        dispatch({
          type: 'SET_USER',
          payload: { data: tempUser, isLoading: false },
        });
      } catch (error) {
        await auth.signOut();
        dispatch({
          type: 'SET_USER',
          payload: { data: null, isLoading: false },
        });
      }
    } else {
      dispatch({
        type: 'SET_USER',
        payload: { isLoading: false },
      });
    }
  }, [authUser, dispatch]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async () => {
      if (authLoading) return;
      await getUserData();
    });

    return () => unsubscribe();
  }, [authLoading, getUserData]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
