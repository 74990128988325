import { Container, Typography } from '@mui/material';

interface Props {
  message: string;
}

export default function PageError({ message }: Props) {
  return (
    <Container maxWidth="lg" className="data-error">
      <Typography variant="h1">{message}</Typography>
    </Container>
  );
}
