import * as React from 'react';
import { Typography } from '@mui/material';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { closeCheckout, validateCheckout } from '../../../services/appointment';
import {
  Appointment,
  AppointmentCheckoutSession,
  Business,
} from '../../../interfaces';
import SubmitButton from './SubmitButton';

interface Props {
  checkoutSession: AppointmentCheckoutSession | null;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  appointment: Appointment;
  business: Business | undefined;
  tipAmtCents: number;
  disabledCheckout?: boolean;
}

export default function NewPaymentCheckoutForm({
  checkoutSession,
  setSubmitting,
  setSuccess,
  setErrorMessage,
  appointment,
  business,
  tipAmtCents,
  disabledCheckout,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const { appointmentPriceCents = 0, totalPriceCents = 0 } =
    checkoutSession || {};

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    setSubmitting(true);
    setErrorMessage('');

    if (
      !stripe ||
      !elements ||
      !appointment.appointmentId ||
      !checkoutSession ||
      disabledCheckout
    ) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const isValid = await validateCheckout({
      appointmentId: appointment.appointmentId,
      previousCheckoutSession: checkoutSession,
      tipAmountCents: tipAmtCents,
      businessId: business?.businessId,
    });
    if (!isValid) {
      setSubmitting(false);
      setErrorMessage(
        'This appointment is no longer available for checkout. Please refresh the page and try again.',
      );
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      setSubmitting(false);
      setErrorMessage(
        result.error.message ||
          'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
      );
      return;
    }

    setSuccess(true);

    const totalPrice = appointmentPriceCents / 100;
    const tipPrice = tipAmtCents / 100;
    await closeCheckout(appointment.appointmentId!, totalPrice, tipPrice).then(
      () => {
        setSuccess(true);
        setTimeout(() => {
          window.location.replace(`/booking/${appointment.appointmentId}`);
        }, 2000);
      },
    );
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="footer-sticky">
        <SubmitButton disabled={disabledCheckout} onSubmit={handleSubmit}>
          Pay ${((totalPriceCents || 0) / 100)?.toFixed(2)}
        </SubmitButton>
        <Typography variant="body2" className="center">
          Powered by Porter
        </Typography>
      </div>
    </form>
  );
}
