import * as yup from 'yup';

export const discountCodeSchema = yup.object({
  discountCode: yup
    .string()
    .trim()
    .min(1, 'Discount code is required')
    .required('Discount code is required'),
});

export type DiscountCodeValues = yup.InferType<typeof discountCodeSchema>;
