/* eslint-disable react/require-default-props */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Fade from '@mui/material/Fade';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

type Props = {
  header: string;
  message: string;
  open: boolean;
  success?: boolean;
  successMessage?: string;
};

function LoadingModal({
  header,
  message,
  open,
  success,
  successMessage,
}: Props) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      {success ? (
        <Box sx={style} className="modal-box">
          <Fade in>
            <div>
              <CheckCircleIcon fontSize="large" color="success" />
              <Typography
                id="modal-modal-title"
                variant="h2"
                className="modal-header">
                {successMessage}
              </Typography>
            </div>
          </Fade>
        </Box>
      ) : (
        <Box sx={style} className="modal-box">
          <Typography
            id="modal-modal-title"
            variant="h2"
            className="modal-header">
            {header}
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body1"
            className="modal-body">
            {message}
          </Typography>
          <CircularProgress />
        </Box>
      )}
    </Modal>
  );
}

export default LoadingModal;
