/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Box, Typography } from '@mui/material';
import { FormikForm, ServiceFormValues } from '../../../interfaces';
import ServiceStep from './ServiceStep';
import BookingProgressBar from '../../Booking/General/BookingProgressBar';
import useGetBusiness from '../../../hooks/businesses/useGetBusiness';
import useSubmitServiceForm from '../../../hooks/appointments/useSubmitServiceForm';
import {
  getBaseStorageIdentifier,
  getFormValuesStorageIdentifier,
  getInitialServiceForm,
  getStepStorageIdentifier,
  getSteps,
  serviceFormSchema,
} from '../../../utils/services';
import LoadingModal from '../../Global/LoadingModal';
import { BOOKING_STEPS_CATEGORY } from '../../../constants/services';
import useUser from '../../../hooks/global/useUser';
import StoreServiceForm from './StoreServiceForm';
import useGetServiceFromParam from '../../../hooks/services/useGetServiceFromParam';
import PageError from '../../Global/Error';

const progressCategories = ['Details', 'Schedule', 'Your Info', 'Confirm'];

function ServiceBookingForm() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { business } = useGetBusiness();
  const service = useGetServiceFromParam(business);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employeeId = searchParams.get('employeeId');

  const storageIdentifier = getBaseStorageIdentifier(service);
  const stepStorageIdentifier = getStepStorageIdentifier(storageIdentifier);
  const valuesStorageIdentifier =
    getFormValuesStorageIdentifier(storageIdentifier);

  const [step, setStep] = React.useState<number>(0);

  const steps = getSteps(service);

  React.useEffect(() => {
    if (sessionStorage.getItem(stepStorageIdentifier)) {
      setStep(Number(sessionStorage.getItem(stepStorageIdentifier)));
    }
  }, [stepStorageIdentifier]);

  const initialFormState = getInitialServiceForm({
    storageIdentifier,
    employeeId,
  });

  const {
    submitServiceFormMutation,
    isPending,
    error: submitError,
  } = useSubmitServiceForm({
    service,
    onSuccess: ({ result, employee }) => {
      window._cio.track('Submit Booking Request', {
        artist: employee.displayName,
      });

      if (user) {
        window._cio.identify({
          id: user.userId || user.result?.userId,
          last_appointment_id: result.appointmentId,
          last_employee_display_name: employee.displayName,
        });
      }

      navigate(`/booking/${result.appointmentId}`);
    },
  });

  const calculateAgeLimit = () => {
    if (!service?.minimumAgeInMonths) return null;
    const minAge =
      service.minimumAgeInMonths % 12 === 0
        ? service.minimumAgeInMonths / 12
        : service.minimumAgeInMonths;
    const minAgeUnit =
      service.minimumAgeInMonths % 12 === 0 ? 'years' : 'months';
    return { minAge, minAgeUnit };
  };

  const renderErrorMessage = () => {
    if (!submitError) return null;

    window.scroll(0, 0);

    const ageLimit = calculateAgeLimit();
    if (submitError.message === 'MIN_AGE_NOT_MET' && ageLimit) {
      if (business?.businessId === 'cm01dmawi009uyhu4jbmdiqo2') {
        return `You must be ${ageLimit.minAge} ${ageLimit.minAgeUnit} or older to book this service online. This service is available to minors but must be booked directly through the shop. Please contact the shop at (516) 794-5844 for more information.`;
      }
      return `You must be ${ageLimit.minAge} ${ageLimit.minAgeUnit} or older to book this service online. Please contact the shop for more information.`;
    }

    return (
      submitError.message ||
      'An unexpected error occurred. Please refresh the page and try again.'
    );
  };

  const form: FormikForm<ServiceFormValues> = {
    validationSchema: serviceFormSchema,
    initialValues: initialFormState,
    onSubmit: async values => {
      if (!values) return;
      await submitServiceFormMutation.mutateAsync(values);
    },
  };

  if (!service || !business) {
    return (
      <PageError
        message="There was an error loading this artist's page. Please double
          check that you've entered the url in correctly, and if the error
          persists, reach out to our support team using the help icon below."
      />
    );
  }

  return (
    <Box>
      <BookingProgressBar
        progressCategories={progressCategories}
        currentProgress={BOOKING_STEPS_CATEGORY[steps[step]]}
      />
      <Formik
        initialValues={form.initialValues}
        validationSchema={form.validationSchema}
        onSubmit={form.onSubmit}>
        <Form>
          {submitError && (
            <Typography
              color="error"
              variant="body2"
              mt={2}
              px={2}
              textAlign="center">
              {renderErrorMessage()}
            </Typography>
          )}
          <ServiceStep step={step} setStep={setStep} />
          <StoreServiceForm storageIdentifier={valuesStorageIdentifier} />
        </Form>
      </Formik>
      {isPending && (
        <LoadingModal
          header="Scheduling appointment..."
          message="This could take a minute. Please do not refresh the page."
          open={isPending}
        />
      )}
    </Box>
  );
}

export default ServiceBookingForm;
