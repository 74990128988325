import { useQuery } from '@tanstack/react-query';
import { createUnsubscribeFormEmbed } from '../../services/marketing';

function useCreateUnsubscribeFormEmbed(sourceCustomerId: string | null) {
  const enabled = !!sourceCustomerId;

  const query = useQuery({
    queryKey: ['unsubscribe-embed', sourceCustomerId],
    queryFn: async () => {
      if (!sourceCustomerId) return null;
      const result = await createUnsubscribeFormEmbed(sourceCustomerId);
      return result;
    },
    enabled,
  });

  return {
    embed: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
}

export default useCreateUnsubscribeFormEmbed;
