import axios from './api';
import API_URL from '../apiconfig';

export async function getTrips(employeeId: string) {
  try {
    const tripsResult = await axios.get(`${API_URL}/v1/trips/${employeeId}`);

    return tripsResult.data.result;
  } catch (error) {
    throw new Error('Cannot get trips');
  }
}
