import { MenuItem, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { ServiceFormValues } from '../../../interfaces';
import PageLayout from '../../Global/PageLayout';
import LoadingIndicator from '../../Global/LoadingIndicator';
import PageError from '../../Global/Error';
import useGetPiercingLocations from '../../../hooks/services/useGetPiercingLocations';

type Props = {
  nextStep: () => void;
  prevStep?: () => void;
};

function SelectPiercingLocation({ nextStep, prevStep }: Props) {
  const formik = useFormikContext<ServiceFormValues>();

  const { categories, types, isLoading, isError } = useGetPiercingLocations(
    formik.values.piercingLocationCategory,
  );

  const questionValues = {
    header: 'Where on your body do you want your piercing?',
  };

  const isValid = !!formik.values.piercingLocationId;
  const showTypes =
    formik.values.piercingLocationCategory && types && types.length > 1;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!categories.length || isError) {
    return (
      <PageError message="Error loading piercing locations. Please refresh the page." />
    );
  }

  return (
    <PageLayout
      header={questionValues.header}
      onNext={nextStep}
      onBack={prevStep}
      nextDisabled={!isValid}>
      <TextField
        id="piercingLocationCategory"
        select
        name="piercingLocationCategory"
        label="Piercing Location"
        value={formik.values.piercingLocationCategory}
        onChange={e => {
          formik.handleChange(e);
          formik.setFieldValue('piercingLocationId', '');
        }}
        error={
          formik.touched.piercingLocationCategory &&
          Boolean(formik.errors.piercingLocationCategory)
        }
        helperText={
          formik.touched.piercingLocationCategory &&
          formik.errors.piercingLocationCategory
        }>
        {categories.map(category => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>

      {showTypes && (
        <TextField
          id="piercingLocationId"
          select
          name="piercingLocationId"
          label="Piercing Location"
          value={formik.values.piercingLocationId}
          onChange={formik.handleChange}
          error={
            formik.touched.piercingLocationId &&
            Boolean(formik.errors.piercingLocationId)
          }
          helperText={
            formik.touched.piercingLocationId &&
            formik.errors.piercingLocationId
          }>
          {types.map(type => (
            <MenuItem key={type.locationId} value={type.locationId}>
              {type.locationType}
            </MenuItem>
          ))}
        </TextField>
      )}
    </PageLayout>
  );
}

export default SelectPiercingLocation;
