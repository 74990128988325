import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Dialog,
  ImageList,
  ImageListItem,
  Slide,
  useMediaQuery,
} from '@mui/material';
import GridOnIcon from '@mui/icons-material/GridOn';
import { TransitionProps } from '@mui/material/transitions';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { fill, limitFill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import './ShopHeader.css';
import { Image } from '../../interfaces';
import MyImageGallery from '../Global/ImageGallery';

interface GalleryImage {
  original: string;
  thumbnail: string;
  cover?: boolean;
}

type Props = {
  images: Image[] | undefined;
};

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />,
);

function TopImage({ images }: Props) {
  const [imageCount, setImageCount] = React.useState<number>();
  const [galleryOpen, setGalleryOpen] = React.useState(false);
  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>();
  const [desktopImages, setDesktopImages] = React.useState<GalleryImage[]>();
  const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(1);
  const desktop = useMediaQuery('(min-width:744px)');

  const handleClickOpen = () => {
    setGalleryOpen(true);
  };

  const handleClose = () => {
    setGalleryOpen(false);
  };

  React.useEffect(() => {
    async function loadImages() {
      if (images && images.length > 0) {
        setImageCount(images.length);
        const tempGalleryImages = await Promise.all(
          images.map(image => {
            const cldImage = new CloudinaryImage(image.imageRef, {
              cloudName: 'dsh0tsol7',
            }).resize(fill().width(780).height(620).gravity(autoGravity()));

            return { original: cldImage.toURL(), thumbnail: cldImage.toURL() };
          }),
        );

        setGalleryImages(tempGalleryImages);
      }
    }
    loadImages();
  }, [images]);

  React.useEffect(() => {
    async function loadDesktopImages() {
      if (images && images.length > 0) {
        setImageCount(images.length);
        const tempDesktopImages = await Promise.all(
          images.slice(0, 5).map(image => {
            const cldImage = new CloudinaryImage(image.imageRef, {
              cloudName: 'dsh0tsol7',
            }).resize(limitFill().width(1080).gravity(autoGravity()));

            return {
              original: cldImage.toURL(),
              thumbnail: cldImage.toURL(),
              cover: image.coverPhoto,
            };
          }),
        );

        setDesktopImages(tempDesktopImages);
      }
    }
    if (desktop && !desktopImages) {
      loadDesktopImages();
    }
  }, [desktop, desktopImages, images]);

  return (
    <Box>
      {!desktop && (
        <Box
          className="shop-header-box"
          sx={{ position: 'relative' }}
          onClick={handleClickOpen}>
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgba(20,20,20,.6)',
              bottom: 16,
              right: 16,
              padding: '3px 7px',
              borderRadius: '5px',
              zIndex: 5,
            }}>
            <Typography variant="h4" color="white">
              {currentImageIndex} / {imageCount}
            </Typography>
          </Box>

          {galleryImages && (
            <ImageGallery
              items={galleryImages}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              onSlide={(index: number) => setCurrentImageIndex(index + 1)}
            />
          )}
        </Box>
      )}

      {desktop && (
        <Box className="shop-header-box-desktop" sx={{ position: 'relative' }}>
          {desktopImages && (
            <ImageList variant="quilted" cols={4} rowHeight={180}>
              {desktopImages.map(item => (
                <ImageListItem
                  key={item.original}
                  cols={item.cover ? 2 : 1}
                  rows={item.cover ? 2 : 1}>
                  <img
                    src={item.original}
                    alt={item.original}
                    loading="lazy"
                    width="100%"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          <Box
            onClick={handleClickOpen}
            sx={{
              position: 'absolute',
              backgroundColor: '#ffffff',
              bottom: 32,
              right: 32,
              padding: '5px 10px',
              borderRadius: '5px',
              zIndex: 5,
              display: 'flex',
              alignItems: 'center',
            }}>
            <GridOnIcon
              sx={{
                marginRight: '5px',
                color: 'darkGrey.main',
              }}
            />
            <Typography variant="h4" color="#333333">
              Show all photos
            </Typography>
          </Box>
        </Box>
      )}

      <Dialog
        fullScreen
        open={galleryOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="gallery-modal">
        <MyImageGallery
          images={images}
          closeGallery={handleClose}
          currentIndex={currentImageIndex}
        />
      </Dialog>
    </Box>
  );
}

export default TopImage;
