import * as React from 'react';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import PageLayout from '../../Global/PageLayout';
import ReviewItem from '../../Global/ReviewItem';
import FormSmallModal, { FormData } from '../../Global/FormSmallModal';
import {
  WaiverForm,
  WaiverSectionProps,
  customerDetailsSchema,
} from '../../../types/waivers';
import { formatPhone } from '../../../utils/global';

export default function ConfirmCustomerDetails({
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const [form, setForm] = React.useState<FormData | null>(null);

  const { customerDetails } = waiverForm.value;

  const formattedPhoneNumber = formatPhone(customerDetails.phoneNumber);

  const customerDetailsForm: FormData<WaiverForm['customerDetails']> = {
    title: 'Edit your information',
    initialValues: {
      ...customerDetails,
    },
    validationSchema: customerDetailsSchema,
    inputRows: [
      [
        {
          inputType: 'text',
          label: 'First name',
          name: 'firstName',
          placeholder: 'John',
        },
      ],
      [
        {
          inputType: 'text',
          label: 'Last name',
          name: 'lastName',
          placeholder: 'Doe',
        },
      ],
      [
        {
          inputType: 'phone',
          label: 'Phone number',
          name: 'phoneNumber',
          placeholder: '+12483026074',
        },
      ],
      [
        {
          inputType: 'email',
          label: 'Email',
          name: 'email',
          placeholder: 'johndoe@gmail.com',
        },
      ],
      [
        {
          inputType: 'birthday',
          label: 'Date of birth',
          name: 'dob',
        },
      ],
    ],
    onSubmit: async values => {
      waiverForm.set(prev => ({
        ...prev,
        customerDetails: values,
      }));
      setForm(null);
    },
  };

  return (
    <PageLayout
      alert={alert}
      header="Confirm your personal details"
      subheader="If any of the below information is not correct, please edit it"
      onNext={onNext}
      onBack={onBack}
      nextDisabled={!customerDetailsSchema.isValidSync(customerDetails)}>
      <Stack spacing={2}>
        <ReviewItem
          label="Your name"
          value={
            `${customerDetails.firstName || ''} ${
              customerDetails.lastName || ''
            }`.trim() || 'Add your name'
          }
          action={() => setForm(customerDetailsForm)}
        />
        <ReviewItem
          label="Phone number"
          value={formattedPhoneNumber}
          action={() => setForm(customerDetailsForm)}
        />
        <ReviewItem
          label="Email"
          value={customerDetails.email || 'Add your email'}
          action={() => setForm(customerDetailsForm)}
        />
        <ReviewItem
          label="Date of Birth"
          value={
            customerDetails.dob
              ? format(
                  new Date(`${customerDetails.dob.split('T')[0]}T00:00:00`),
                  'MM/dd/yyyy',
                )
              : 'Add your date of birth'
          }
          action={() => setForm(customerDetailsForm)}
        />
      </Stack>
      <FormSmallModal open={!!form} onClose={() => setForm(null)} form={form} />
    </PageLayout>
  );
}
