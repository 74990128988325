import axios from './api';
import API_URL from '../apiconfig';

export async function getIntakeQuestion(questionId: string) {
  try {
    const intakeQuestion = await axios.get(
      `${API_URL}/v1/intake-questions/${questionId}`,
    );

    return intakeQuestion.data.result;
  } catch (error) {
    throw new Error('Cannot get Intake Question');
  }
}
