import * as React from 'react';
import Typography from '@mui/material/Typography';
import './ArtistHeader.css';
import { Employee, Business, TripStudio } from '../../interfaces';

type Props = {
  employee: Employee | undefined;
  business: Business | undefined;
  // eslint-disable-next-line react/require-default-props
  tripStudio?: TripStudio | undefined;
};

function EmployeeHeader({ employee, business, tripStudio }: Props) {
  const [employeeName, setEmployeeName] = React.useState<string>();
  const [businessName, setBusinessName] = React.useState<string>();
  const [businessCity, setBusinessCity] = React.useState<string>();
  const [businessState, setBusinessState] = React.useState<string>();
  const [businessZip, setBusinessZip] = React.useState<string>();

  React.useEffect(() => {
    if (employee) {
      const tempName =
        employee?.displayName ||
        `${employee?.user.firstName} ${employee?.user.lastName}`;

      setEmployeeName(tempName);
    }

    if (business && !tripStudio) {
      setBusinessName(business.name);
      setBusinessCity(business.city);
      setBusinessState(business.state);
      setBusinessZip(business.zip);
    }

    if (tripStudio) {
      setBusinessName(tripStudio.studioName);
      setBusinessState(tripStudio.studioState);
      setBusinessCity(tripStudio.studioCity);
      setBusinessZip(tripStudio.studioZip);
    }
  }, [employee, business, tripStudio]);

  return (
    <div className="artist-header">
      <img
        className="artist-thumbnail"
        src={employee?.profileImage || business?.thumbnailImage}
        alt="Aritst profile"
      />
      <div className="artist-header-details">
        <Typography variant="h1" gutterBottom>
          {employeeName || businessName}
        </Typography>

        {businessName && (
          <Typography variant="body1">{businessName}</Typography>
        )}
        <Typography variant="body1">
          {businessCity}, {businessState} {businessZip}
        </Typography>
      </div>
    </div>
  );
}

export default EmployeeHeader;
