/* eslint-disable react/require-default-props */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Employee } from '../../interfaces';
import FormHeader from './FormHeader';

type CityOptions = {
  name: string;
  timezone: string | undefined;
  state: string;
  businessId?: string;
};

type Props = {
  employee: Employee | undefined;
  appointmentCity: string;
  setAppointmentCity?: React.Dispatch<React.SetStateAction<string>>;
  setAppointmentTimezone?: React.Dispatch<React.SetStateAction<string>>;
  setNameStep: React.Dispatch<React.SetStateAction<number>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (field: string, value: any) => void;
};

const validationSchema = yup.object({
  city: yup.string().required('Please select a city from the dropdown'),
});

function AppointmentCity({
  employee,
  appointmentCity,
  setAppointmentCity,
  setAppointmentTimezone,
  setNameStep,
  setSubmit,
  setFieldValue,
}: Props) {
  const [loading, setLoading] = React.useState(true);
  const [cityOptions, setCityOptions] = React.useState<CityOptions[]>();
  const [artistName, setArtistName] = React.useState<string>();

  React.useEffect(() => {
    if (employee) {
      setArtistName(
        employee.displayName
          ? employee.displayName
          : `${employee.user.firstName} ${employee.user.lastName}`,
      );
    }
  }, [employee]);

  React.useEffect(() => {
    async function fetchData() {
      if (employee) {
        const uniqueCityOptions: CityOptions[] = [];

        uniqueCityOptions.push({
          name: employee.business?.city || '',
          timezone: employee.user.timezone,
          state: employee.business?.state || '',
          businessId: employee.business?.businessId,
        });

        employee.trips!.forEach(trip => {
          if (
            !uniqueCityOptions.some(
              (cityOption: CityOptions) => cityOption.name === trip.city,
            ) &&
            trip.booksOpen
          ) {
            uniqueCityOptions.push({
              name: trip.city,
              timezone: trip.timezone,
              state: trip.state,
            });
          }
        });

        setCityOptions(uniqueCityOptions);
        setLoading(false);
      }
    }

    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      city: appointmentCity,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setSubmit(true);
      const cityOption = cityOptions!.find(item => item.name === values.city);
      if (setAppointmentCity) {
        setAppointmentCity(values.city);
      }
      if (cityOption && cityOption.timezone) {
        sessionStorage.setItem('timezone', cityOption.timezone);
        if (setAppointmentTimezone) {
          setAppointmentTimezone(cityOption.timezone);
        }
      }
      setFieldValue('businessId', cityOption?.businessId ?? '');
      sessionStorage.setItem('businessId', cityOption?.businessId ?? '');
      sessionStorage.setItem('city', values.city!);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="appointment-city">
      <FormHeader
        header={`Which city do you want to schedule ${artistName} in?`}
      />

      {loading ? <Typography variant="inherit">Loading...</Typography> : null}
      {!loading && cityOptions ? (
        <Box className="input-container">
          <TextField
            id="city"
            select
            name="city"
            label="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}>
            {cityOptions.map(cityOption => (
              <MenuItem
                key={cityOption.name}
                value={cityOption.name}
                className="appointment-city-choice">
                {cityOption.name}, {cityOption.state}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      ) : null}
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryAction={() => setNameStep(1)}
        secondaryText="Back"
      />
    </form>
  );
}

export default AppointmentCity;
