import { createContext } from 'react';
import { User } from '../../interfaces';

export interface AppState {
  user: {
    data: User | null;
    isLoading: boolean;
    selectedBusiness: string;
  };
}

interface AppContextType {
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>; // Replace 'any' with the actual action type if you have one
}

export const initialState: AppState = {
  user: {
    data: null,
    isLoading: true,
    selectedBusiness: sessionStorage.getItem('selectedBusiness') || '',
  },
};

export type AppStateAction = {
  type: 'SET_USER';
  payload: Partial<AppState['user']>;
};

// Providing an initial value for dispatch as well (a no-op function in this case)
const defaultContextValue: AppContextType = {
  state: initialState,
  dispatch: () => {},
};

export const AppContext = createContext<AppContextType>(defaultContextValue);
