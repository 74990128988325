import { QueryClientProvider } from '@tanstack/react-query';
import './App.css';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import client from './utils/react-query/client';
import { AppProvider } from './context/AppContext';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <AppProvider>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <Outlet />
        </AppProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
