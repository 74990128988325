import * as React from 'react';
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { DayItem } from '../../../../interfaces';
import DaysRow from './DaysRow';

type Props = {
  dayItems: DayItem[];
  selectedMonth: Date;
  setSelectedDay: React.Dispatch<React.SetStateAction<Date>>;
  setDayIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  showOnlyPreferred: boolean;
};

export default function Month({
  dayItems,
  selectedMonth,
  setSelectedDay,
  setDayIsSelected,
  showOnlyPreferred,
}: Props) {
  const weeks = React.useMemo(() => {
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth();
    const startOfMonthDate = startOfMonth(new Date(year, month));
    const endOfMonthDate = endOfMonth(new Date(year, month));

    const startOfCalendar = startOfWeek(startOfMonthDate);
    const endOfCalendar = endOfWeek(endOfMonthDate);

    const allDates = eachDayOfInterval({
      start: startOfCalendar,
      end: endOfCalendar,
    });

    const tempWeeks: Date[][] = [];
    let week: Date[] = [];

    allDates.forEach(date => {
      week.push(date);
      if (week.length === 7) {
        tempWeeks.push(week);
        week = [];
      }
    });

    return tempWeeks;
  }, [selectedMonth]);

  const rows = React.useMemo<DayItem[][]>(() => {
    const tempRows: DayItem[][] = [];
    for (let i = 0; i < weeks.length; i++) {
      const row = weeks[i].map(date => {
        const dateString = format(date, 'yyyy-MM-dd');
        const dayItem: DayItem = dayItems.find(
          day => dateString === day.date,
        ) ?? {
          date: dateString,
          available: false,
        };
        return dayItem;
      });
      tempRows.push(row);
    }
    return tempRows;
  }, [dayItems, weeks]);

  return (
    <div className="calendar-rows">
      {rows.map(row => (
        <DaysRow
          dayItems={row}
          showOnlyPreferred={showOnlyPreferred}
          selectedMonth={selectedMonth}
          setSelectedDay={setSelectedDay}
          setDayIsSelected={setDayIsSelected}
          key={row[0].date}
        />
      ))}
    </div>
  );
}
