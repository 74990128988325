import { Box, Button, Modal, SxProps, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { CSSProperties, PropsWithChildren } from 'react';
import { LoadingButton } from '@mui/lab';
import { important } from '../../../utils/global';

const style: SxProps<Theme> = {
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  overflowY: 'auto',
};

export interface FooterProps {
  button: {
    disabled?: boolean;
    loading?: boolean;
    type?: 'submit' | 'button';
    text: string;
    onClick?: () => void | Promise<void>;
  };
}

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  title?: string;
  footer?: FooterProps;
  hideCloseButton?: boolean;
  width?: CSSProperties['width'];
}

export function Footer({ button }: FooterProps) {
  return (
    <Box
      p={2}
      position="sticky"
      bottom={0}
      zIndex={1}
      boxSizing="border-box"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      borderTop={theme => `0.5px solid ${theme.palette.lightOutlineGrey.main}`}
      bgcolor="background.paper">
      <LoadingButton
        type={button.type ?? 'submit'}
        className="primary"
        disabled={button.disabled}
        variant="contained"
        color="primary"
        sx={{
          height: '40px',
          whiteSpace: 'nowrap',
          borderRadius: '8px',
          fontSize: '0.875rem',
          paddingX: important('3rem'),
        }}
        loading={button.loading}>
        <span>{button.text}</span>
      </LoadingButton>
    </Box>
  );
}

function SmallModal({
  open,
  onClose,
  title,
  children,
  footer,
  hideCloseButton,
  width = '410px',
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        borderRadius="16px"
        maxHeight="90%"
        boxShadow={24}
        width={{
          xs: '90%',
          md: width,
        }}
        sx={style}>
        {(title || !hideCloseButton) && (
          <Box
            position="sticky"
            top={0}
            bgcolor="background.paper"
            zIndex={1}
            width="100%"
            boxSizing="border-box"
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="50px"
            borderBottom={theme =>
              `0.5px solid ${theme.palette.lightOutlineGrey.main}`
            }>
            {title && (
              <Typography textAlign="center" variant="h5">
                {title || ''}
              </Typography>
            )}
            {!hideCloseButton && (
              <Button
                variant="text"
                onClick={onClose}
                color="inherit"
                sx={{
                  padding: 0,
                  width: 'auto',
                  minWidth: 0,
                  position: 'absolute',
                  right: 12,
                  top: 12,
                }}>
                <CloseIcon
                  sx={theme => ({ color: theme.palette.darkGrey.main })}
                />
              </Button>
            )}
          </Box>
        )}

        {children}

        {footer && <Footer {...footer} />}
      </Box>
    </Modal>
  );
}

export default SmallModal;
