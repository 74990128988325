import { useMutation } from '@tanstack/react-query';
import { differenceInMonths } from 'date-fns';
import useUser from '../global/useUser';
import {
  CreateAppointmentResult,
  Employee,
  Service,
  ServiceFormValues,
} from '../../interfaces';
import { serviceFormSchema } from '../../utils/services';
import { createUpdateCustomer } from '../../services/customer';
import { createServiceAppointment } from '../../services/appointment';
import { getEmployeeById } from '../../services/employee';

interface SubmitFormOptions {
  service?: Service;
  onSuccess?: ({
    result,
    employee,
  }: {
    result: CreateAppointmentResult;
    employee: Employee;
  }) => void | Promise<void>;
  onError?: (
    error: Error,
    variables: ServiceFormValues,
  ) => void | Promise<void>;
}

function useSubmitServiceForm({
  service,
  onSuccess,
  onError,
}: SubmitFormOptions) {
  const { user } = useUser();

  const submitServiceFormMutation = useMutation({
    mutationFn: async (payload: ServiceFormValues) => {
      if (!user) throw new Error('Not authenticated');

      if (!service || !payload.employeeId)
        throw new Error('Invalid service or employee');

      const employee = await getEmployeeById(payload.employeeId);

      if (!employee) throw new Error('Invalid employee');

      const data = (await serviceFormSchema
        .noUnknown()
        .validate(payload, { stripUnknown: true })) as ServiceFormValues;

      if (
        service.minimumAgeInMonths &&
        service.minimumAgeInMonths > 0 &&
        (!payload.dob ||
          differenceInMonths(new Date(), new Date(payload.dob)) <
            service.minimumAgeInMonths)
      ) {
        throw new Error('MIN_AGE_NOT_MET');
      }

      await createUpdateCustomer(data, user).catch(() => {
        throw new Error('Failed to create/update customer');
      });

      const appointmentResponse = await createServiceAppointment(
        payload,
        employee,
        user,
        service,
      );

      return { result: appointmentResponse, employee };
    },
    onSuccess,
    onError,
  });

  return {
    submitServiceFormMutation,
    isPending: submitServiceFormMutation.isPending,
    isSuccess: submitServiceFormMutation.isSuccess,
    isError: submitServiceFormMutation.isError,
    error: submitServiceFormMutation.error,
  };
}

export default useSubmitServiceForm;
