import ReactPhoneInput from 'react-phone-input-2';
import { useFormikContext } from 'formik';
import InputWrapper from '../InputWrapper';

import 'react-phone-input-2/lib/style.css';
import './styles.css';

interface TextInputProps {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
}

function PhoneInput({ name, label, placeholder, disabled }: TextInputProps) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <InputWrapper label={label} name={name}>
      <div className="input phone-input" style={{ position: 'relative' }}>
        <ReactPhoneInput
          inputProps={{
            id: name,
            name,
          }}
          placeholder={placeholder}
          country="us"
          value={(values as any)[name] || ''}
          onChange={val => {
            setFieldValue(name, `+${val}`);
          }}
          disabled={disabled}
          disableDropdown={disabled}
        />
      </div>
    </InputWrapper>
  );
}

export default PhoneInput;
