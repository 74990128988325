import axios from './api';
import API_URL from '../apiconfig';
import { PiercingLocation } from '../interfaces';

export async function getChoices(category: string) {
  try {
    const choicesResult = await axios.get(`${API_URL}/v1/choices/tattoo`, {
      params: {
        category,
      },
    });

    return choicesResult.data.result;
  } catch (error) {
    throw new Error('Cannot get tattoo choices');
  }
}

export async function getPiercingLocations() {
  try {
    const choicesResult = await axios.get(
      `${API_URL}/v1/choices/piercing-locations`,
    );

    return choicesResult.data.result as PiercingLocation[];
  } catch (error) {
    throw new Error('Cannot get piercing locations');
  }
}
