import { WaiverSectionProps } from '../../../types/waivers';
import PageLayout from '../../Global/PageLayout';
import WaiverOptionsList from './WaiverOptionsList';

export default function MedicalHistory({
  waiver,
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  return (
    <PageLayout
      alert={alert}
      header="Medical History"
      subheader={waiver?.medicalHistorySettings?.intro}
      onNext={onNext}
      onBack={onBack}>
      <WaiverOptionsList
        options={waiver?.medicalHistorySettings?.options.filter(
          o => o.type === 'CHECKBOX',
        )}
        type="medicalHistory"
        waiverForm={waiverForm}
      />
      <WaiverOptionsList
        options={waiver?.medicalHistorySettings?.options.filter(
          o => o.type === 'TEXT',
        )}
        type="medicalHistory"
        waiverForm={waiverForm}
      />
    </PageLayout>
  );
}
