import { useState, useEffect } from 'react';
/**
 * useDetectKeyboardOpen
 *
 * @param {object} options
 * @param {function} options.onKeyboardOpen - Callback function to be called when the keyboard is opened
 * @param {function} options.onKeyboardClose - Callback function to be called when the keyboard is closed
 *
 * @returns {boolean} isKeyboardOpen
 *
 * @example
 * const isKeyboardOpen = useDetectKeyboardOpen()
 *
 */

const useDetectKeyboardOpen = ({
  onKeyboardOpen,
  onKeyboardClose,
}: {
  onKeyboardOpen?: () => void;
  onKeyboardClose?: () => void;
} = {}) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const isTouchScreen =
      'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const listener = () => {
      if (!window.visualViewport) return;
      const MIN_KEYBOARD_HEIGHT = 300; // this might not always be correct

      setIsKeyboardOpen(
        // if the keyboard is open, the visual viewport height will be less than the screen height
        isTouchScreen &&
          window.screen.height - MIN_KEYBOARD_HEIGHT >
            window.visualViewport.height,
      );
    };

    if (window.visualViewport)
      window.visualViewport.addEventListener('resize', listener);

    return () => {
      if (window.visualViewport)
        window.visualViewport.removeEventListener('resize', listener);
    };
  }, [onKeyboardClose, onKeyboardOpen]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
