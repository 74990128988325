import React from 'react';
import {
  AcknowledgmentSettings,
  MedicalHistorySettings,
  StaticWaiverSettings,
  WaiverIdentificationSettings,
  WaiverOptions,
  WaiverRequests,
  Waivers,
} from '@prisma/client';
import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Business } from '../../interfaces';
import { FormHeaderAlert } from '../../components/Form/FormHeader';

export type FullWaiver = Waivers & {
  staticWaiverSettings: StaticWaiverSettings | null;
  acknowledgmentSettings:
    | (AcknowledgmentSettings & {
        options: WaiverOptions[];
      })
    | null;
  medicalHistorySettings:
    | (MedicalHistorySettings & {
        options: WaiverOptions[];
      })
    | null;
  identificationSettings: WaiverIdentificationSettings | null;
};

export interface WaiverSectionProps {
  employee:
    | {
        firstName: string;
        lastName: string;
      }
    | undefined;
  isPreview: boolean;
  business: Business;
  waiver: FullWaiver | null;
  onNext: () => void;
  onBack: (() => void) | undefined;
  onSubmit: (finalSignature: string) => void | Promise<void>;
  waiverForm: {
    value: WaiverForm;
    set: React.Dispatch<React.SetStateAction<WaiverForm>>;
  };
  alert?: FormHeaderAlert;
}

export interface UpdateWaiverResponse extends WaiverRequests {}

export interface TrackWaiverViewResponse extends WaiverRequests {}

export interface SubmitWaiverPayload {
  waiverRequestId: string;
  signedFileLink: string;
  data: {
    acknowledgments: WaiverForm['acknowledgments'];
    medicalHistory: WaiverForm['medicalHistory'];
    customerDetails: WaiverForm['customerDetails'];
  };
  identificationImageLink?: string;
}

export interface TrackWaiverViewPayload {
  waiverRequestId: string;
}

export const customerDetailsSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup
    .string()
    .test({
      test: value => {
        if (!value) return false;
        return isValidPhoneNumber(value);
      },
      message: 'Invalid phone number',
    })
    .required('Phone number is required'),
  email: yup.string().email().required('Email is required'),
  dob: yup.string().test({
    test: val => {
      try {
        return !!val && !!new Date(val)?.getTime();
      } catch {
        return false;
      }
    },
    message: 'Date of birth is required',
  }),
});

export const waiverFormSchema = yup.object({
  customerDetails: customerDetailsSchema,
  acknowledgments: yup.object().required(),
  medicalHistory: yup.object().required(),
  staticLiabilitySignature: yup.string().defined().strict(true),
  signature: yup.string().min(1).required(),
  identification: yup.string().defined().strict(true),
});

export interface WaiverForm {
  customerDetails: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    dob: string;
  };
  acknowledgments: {
    [key: string]: boolean;
  };
  medicalHistory: {
    [key: string]: boolean | string;
  };
  staticLiabilitySignature: string;
  signature: string;
  identification: string;
}
