import { MutableRefObject } from 'react';
import { Box } from '@mui/material';
import { PocketflowsUnsubscribeFormEmbed } from '../../types/marketing';

interface Props {
  unsubscribeEmbed: PocketflowsUnsubscribeFormEmbed;
  iframeRef: MutableRefObject<HTMLIFrameElement | null>;
}

function UnsubscribeEmbed({ unsubscribeEmbed, iframeRef }: Props) {
  return (
    <Box
      width="100%"
      height="100%"
      flex={1}
      position="relative"
      style={{ marginTop: '64px' }}>
      <iframe
        ref={iframeRef}
        src={unsubscribeEmbed.url}
        width="100%"
        height="100%"
        frameBorder="0"
        title="email-template"
      />
    </Box>
  );
}

export default UnsubscribeEmbed;
