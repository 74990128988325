import * as React from 'react';
import { format, addMonths, subMonths, addDays, isSameMonth } from 'date-fns';
import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material';

type Props = {
  selectedMonth: Date;
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date | null>>;
  showOnlyPreferred: boolean;
  setShowOnlyPreferred: React.Dispatch<React.SetStateAction<boolean>>;
  bookAheadLimitDays: number;
  hideButtons?: boolean;
};

export default function CalendarNav({
  selectedMonth,
  setSelectedMonth,
  showOnlyPreferred,
  setShowOnlyPreferred,
  bookAheadLimitDays,
  hideButtons,
}: Props) {
  const theme = useTheme();

  const [isLastMonth, setIsLastMonth] = React.useState(false);
  const [isFirstMonth, setIsFirstMonth] = React.useState(false);
  const [lastMonthMessageVisible, setLastMonthMessageVisible] =
    React.useState(false);

  const prevMonth = React.useCallback(() => {
    setSelectedMonth(subMonths(selectedMonth, 1));
    setShowOnlyPreferred(false);
    setIsLastMonth(false);
    setLastMonthMessageVisible(false);
  }, [selectedMonth, setSelectedMonth, setShowOnlyPreferred]);

  const nextMonth = React.useCallback(() => {
    if (!isLastMonth) {
      setSelectedMonth(addMonths(selectedMonth, 1));
      setShowOnlyPreferred(false);
    } else {
      setLastMonthMessageVisible(true);
    }
  }, [isLastMonth, setSelectedMonth, selectedMonth, setShowOnlyPreferred]);

  const monthFormat = 'MMMM yyyy';

  React.useEffect(() => {
    const lastBookableDay = addDays(new Date(), bookAheadLimitDays);
    const tempIsLastMonth = isSameMonth(lastBookableDay, selectedMonth);
    if (tempIsLastMonth || lastBookableDay < selectedMonth) {
      setIsLastMonth(true);
    }
  }, [bookAheadLimitDays, selectedMonth]);

  React.useEffect(() => {
    const firstBookableDay = new Date();
    const tempIsFirstMonth = isSameMonth(firstBookableDay, selectedMonth);
    setIsFirstMonth(tempIsFirstMonth);
  }, [selectedMonth]);

  return (
    <div className="calendar-header">
      <Typography variant="h3" style={{ textAlign: 'center' }}>
        Select a day
      </Typography>
      <div className="calendar-header-text">
        {!hideButtons && (
          <button
            type="button"
            className="month-nav-button"
            onClick={prevMonth}
            disabled={isFirstMonth}>
            <ChevronLeftIcon
              fontSize="medium"
              htmlColor={
                isFirstMonth
                  ? theme.palette.medGrey.main
                  : theme.palette.primary.main
              }
            />
          </button>
        )}
        <Typography variant="body2" style={{ width: 175, textAlign: 'center' }}>
          {format(selectedMonth, monthFormat)}
        </Typography>
        {!hideButtons && (
          <button
            type="button"
            className="month-nav-button"
            onClick={nextMonth}
            disabled={isLastMonth}>
            <ChevronRightIcon
              fontSize="medium"
              htmlColor={
                isLastMonth
                  ? theme.palette.medGrey.main
                  : theme.palette.primary.main
              }
            />
          </button>
        )}
      </div>

      {lastMonthMessageVisible ? (
        <Typography variant="body2">
          Can&apos;t book past{' '}
          {format(addDays(new Date(), bookAheadLimitDays), 'MMMM do yyyy')}
        </Typography>
      ) : null}
      {showOnlyPreferred ? (
        <Typography
          variant="body2"
          className="link"
          onClick={() => setShowOnlyPreferred(false)}>
          These days don&apos;t work for me
        </Typography>
      ) : null}
    </div>
  );
}
