/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../EmployeeHeader/ArtistHeader.css';

interface Props {
  currentProgress: string;
  progressCategories: string[];
}

function BookingProgressBar({ currentProgress, progressCategories }: Props) {
  const theme = useTheme();
  const [lineBreak, setLineBreak] = React.useState<number>();
  const [lineStyle, setLineStyle] = React.useState<any>();
  const [progNum, setProgNum] = React.useState<number>();

  React.useEffect(() => {
    const tempProgNum = progressCategories.indexOf(currentProgress);
    let tempLineBreak = 0;

    if (tempProgNum === progressCategories.length - 1) {
      tempLineBreak = 100;
    } else if (tempProgNum === 0) {
      tempLineBreak = 0;
    } else {
      tempLineBreak = (100 / (progressCategories.length - 1)) * tempProgNum;
    }

    const tempLineStyle = {
      background: `linear-gradient(to right, ${theme.palette.accent.main} 0%, ${theme.palette.accent.main} ${lineBreak}%, ${theme.palette.lightGrey.main} ${lineBreak}%, ${theme.palette.lightGrey.main} 100%`,
    };

    setProgNum(tempProgNum);
    setLineBreak(tempLineBreak);
    setLineStyle(tempLineStyle);
  }, [
    currentProgress,
    lineBreak,
    progressCategories,
    theme.palette.accent.main,
    theme.palette.lightGrey.main,
  ]);

  return (
    <Box className="progress-bar">
      <Box className="progress-steps">
        {progressCategories?.map((section, index) => (
          <Box key={section} className="step">
            <div
              className={`step-circle ${
                (progNum || progNum === 0) && progNum >= index ? 'done' : ''
              }`}>
              <Typography variant="h4" color="darkGrey">
                {index + 1}
              </Typography>
            </div>
            <div className="step-label">
              <Typography variant="body2">{section}</Typography>
            </div>
          </Box>
        ))}
      </Box>

      <div className="progress-line" style={lineStyle} />
    </Box>
  );
}

export default BookingProgressBar;
