import { EmployeesToBusinesses } from '@prisma/client';
import axios from './api';
import API_URL from '../apiconfig';
import {
  Employee,
  IntakeQuestion,
  EmployeeToService,
  Service,
} from '../interfaces';
import { getBusinessById } from './business';
import { getIntakeQuestion } from './intakeQuestion';
import { getTrips } from './trips';
import { transformAndFilterServices } from '../utils/services';

interface EmployeeToQuestion {
  employeeId: string;
  questionId: string;
}

export async function getEmployeeIntakeQuestions(
  employeesToQuestions: EmployeeToQuestion[],
) {
  const intakeQuestions: IntakeQuestion[] = [];

  try {
    employeesToQuestions.forEach(
      async (employeeToQuestion: EmployeeToQuestion) => {
        const intakeQuestion = await getIntakeQuestion(
          employeeToQuestion.questionId,
        ).catch(() => {});

        intakeQuestions.push(intakeQuestion);
      },
    );
  } catch (error) {
    throw new Error('Cannot get Intake Questions');
  }

  return intakeQuestions;
}

export async function getEmployeeByUrlPath(urlPath: string | undefined) {
  try {
    const employeeResult = await axios
      .get(`${API_URL}/v1/employees/${urlPath}`, {
        params: {
          searchBy: 'urlPath',
          shop: true,
          intakeQuestions: true,
          services: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find employee');
      });

    if (employeeResult) {
      const tempEmployee = employeeResult.data.result;

      const intakeQuestions = await getEmployeeIntakeQuestions(
        tempEmployee.employeesToQuestions,
      );

      const primaryEmployeeToBusinessMapping =
        tempEmployee.primaryEmployeeToBusinessId
          ? tempEmployee.employeesToBusinesses.find(
              (m: EmployeesToBusinesses) =>
                m.id === tempEmployee.primaryEmployeeToBusinessId,
            ) || tempEmployee.employeesToBusinesses[0]
          : tempEmployee.employeesToBusinesses[0];

      const business = await getBusinessById(
        primaryEmployeeToBusinessMapping.businessId,
      );

      const { isBookable } = primaryEmployeeToBusinessMapping;

      const services: Service[] =
        tempEmployee.employeesToServices &&
        tempEmployee.employeesToServices.length > 0
          ? tempEmployee.employeesToServices.map(
              (ets: EmployeeToService) => ets.service,
            )
          : [];

      const transformedServices = transformAndFilterServices(services, [
        business,
      ]);

      const trips = await getTrips(tempEmployee.employeeId);

      const employee: Employee = {
        employeeId: tempEmployee.employeeId,
        onschedId: tempEmployee.onschedResourceId,
        displayName: tempEmployee.displayName,
        about: tempEmployee.about,
        profileImage: tempEmployee.profileImage,
        urlPath: tempEmployee.urlPath,
        standardDesignMessage: tempEmployee.standardDesignMessage,
        cancellationPolicyText: tempEmployee.cancellationPolicyText,
        minCancellationDays: tempEmployee.minCancellationDays,
        maxCancellationTimes: tempEmployee.maxCancellationTimes,
        bookAheadLimitDays: tempEmployee.bookAheadLimitDays || 365,
        bookInAdvanceMinutes: tempEmployee.bookInAdvanceMinutes,
        user: tempEmployee.user,
        stackDays: tempEmployee.stackDays,
        stackTimes: tempEmployee.stackTimes,
        minAppointmentPrice: tempEmployee.minAppointmentPrice,
        reschedulePolicy: tempEmployee.reschedulePolicy,
        intakeQuestions,
        images: tempEmployee.images,
        business,
        faqs: tempEmployee.faqs,
        trips,
        createdAt: tempEmployee.createdAt,
        services: transformedServices,
        isBookable,
      };

      return employee;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get employee');
  }
}

export async function getEmployeeById(employeeId: string) {
  try {
    const employeeResult = await axios
      .get(`${API_URL}/v1/employees/${employeeId}`, {
        params: {
          searchBy: 'employeeId',
          shop: true,
        },
      })
      .catch(() => {
        throw new Error('Cannot find employee');
      });

    if (employeeResult) {
      const tempEmployee = employeeResult.data.result;

      const primaryEmployeeToBusinessMapping =
        tempEmployee.employeesToBusinesses.find(
          (m: EmployeesToBusinesses) =>
            m.id === tempEmployee.primaryEmployeeToBusinessId,
        );

      const business = await getBusinessById(
        primaryEmployeeToBusinessMapping?.businessId ||
          tempEmployee.employeesToBusinesses[0].businessId,
      );

      const trips = await getTrips(tempEmployee.employeeId);

      const employee: Employee = {
        employeeId: tempEmployee.employeeId,
        onschedId: tempEmployee.onschedResourceId,
        displayName: tempEmployee.displayName,
        about: tempEmployee.about,
        profileImage: tempEmployee.profileImage,
        urlPath: tempEmployee.urlPath,
        standardDesignMessage: tempEmployee.standardDesignMessage,
        cancellationPolicyText: tempEmployee.cancellationPolicyText,
        minCancellationDays: tempEmployee.minCancellationDays,
        maxCancellationTimes: tempEmployee.maxCancellationTimes,
        minAppointmentPrice: tempEmployee.minAppointmentPrice,
        bookAheadLimitDays: tempEmployee.bookAheadLimitDays || 365,
        bookInAdvanceMinutes: tempEmployee.bookInAdvanceMinutes,
        user: tempEmployee.user,
        liabilityWaiver: tempEmployee.liabilityWaiver,
        stackDays: tempEmployee.stackDays,
        stackTimes: tempEmployee.stackTimes,
        reschedulePolicy: tempEmployee.reschedulePolicy,
        images: tempEmployee.images,
        business,
        faqs: tempEmployee.faqs,
        trips,
        createdAt: tempEmployee.createdAt,
        reviewLink: tempEmployee.reviewLink,
      };

      return employee;
    }

    return false;
  } catch (error) {
    throw new Error('Cannot get employee');
  }
}
