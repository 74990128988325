import { ServiceCategory } from '@prisma/client';
import API_URL from '../apiconfig';
import axios from './api';
import { getCurrentUserAuthorization } from './global';
import { AvailableTimes } from '../interfaces';

export async function getAvailableDays({
  startDate,
  endDate,
  duration,
  employeeId,
  timezone,
  businessId,
  onschedServiceId,
  serviceCategory,
}: {
  startDate: string;
  endDate: string;
  duration: number;
  employeeId: string;
  timezone: string;
  businessId?: string;
  onschedServiceId?: string;
  serviceCategory?: ServiceCategory;
}) {
  const authorization = await getCurrentUserAuthorization();
  let url = `${API_URL}/v1/availability`;
  if (!authorization) {
    url += '/public';
  }

  try {
    const response = await axios.get(url, {
      params: {
        by: 'time',
        startDate,
        endDate,
        duration,
        employeeId,
        timezone,
        onschedServiceId,
        businessId,
        serviceCategory,
      },
    });

    return response.data.result;
  } catch (error) {
    throw new Error('Cannot get available days');
  }
}

export async function getAvailableTimes({
  startDate,
  endDate,
  duration,
  employeeId,
  timezone,
  onschedServiceId,
  businessId,
  serviceCategory,
}: {
  startDate: string;
  endDate: string;
  duration: number;
  employeeId: string;
  timezone: string;
  onschedServiceId?: string;
  businessId?: string;
  serviceCategory?: ServiceCategory;
}) {
  const authorization = await getCurrentUserAuthorization();
  let url = `${API_URL}/v1/availability`;
  if (!authorization) {
    url += '/public';
  }

  try {
    const response = await axios.get(url, {
      params: {
        by: 'time',
        startDate,
        endDate,
        duration,
        employeeId,
        timezone,
        onschedServiceId,
        businessId,
        serviceCategory,
      },
    });

    return response.data.result as { availableTimes: AvailableTimes[] };
  } catch (error) {
    throw new Error('Cannot get available times');
  }
}
