import { useQuery } from '@tanstack/react-query';
import { addMinutes, format, isSameMinute, subMinutes } from 'date-fns';
import { ServiceCategory } from '@prisma/client';
import { getAvailableTimes } from '../../services/availability';
import {
  Appointment,
  AvailableTimes,
  Employee,
  Service,
} from '../../interfaces';

function useDayAvailableTimes({
  day,
  selectedDay,
  employee,
  timezone,
  duration,
  service,
  appointment,
}: {
  day: Date | null;
  selectedDay: Date;
  employee: Employee;
  timezone: string;
  duration: number;
  service?: Service;
  appointment: Appointment | null;
}) {
  const formattedDay = format(selectedDay, 'yyyy-MM-dd');

  const getDayAvailableTimes = async () => {
    const tempAvailableTimes = await getAvailableTimes({
      startDate: formattedDay,
      endDate: formattedDay,
      duration,
      employeeId: employee.employeeId,
      timezone,
      onschedServiceId: service?.onschedServiceId,
      businessId: appointment?.businessId,
      serviceCategory: appointment?.serviceCategory as ServiceCategory,
    });

    const times = tempAvailableTimes.availableTimes;
    if (employee.stackTimes) {
      const filteredAvailableTimes: AvailableTimes[] = [];

      for (let i = 0; i < times.length; i += 1) {
        const currentTime = times[i];
        const prevTime = times[i - 1];
        const nextTime = times[i + 1];

        const currentStartTime = new Date(currentTime.startDateTime);

        if (!nextTime || !prevTime) {
          filteredAvailableTimes.push(currentTime);
        }

        if (prevTime && nextTime) {
          const prevStartTime = new Date(prevTime.startDateTime);
          const nextStartTime = new Date(nextTime.startDateTime);

          if (!isSameMinute(currentStartTime, addMinutes(prevStartTime, 30))) {
            filteredAvailableTimes.push(currentTime);
          } else if (
            !isSameMinute(currentStartTime, subMinutes(nextStartTime, 30))
          ) {
            filteredAvailableTimes.push(currentTime);
          }
        }
      }

      const finalFilteredTimes = filteredAvailableTimes.filter(
        availableTime =>
          new Date(availableTime.startDateTime) >=
          addMinutes(new Date(), employee.bookInAdvanceMinutes),
      );

      return finalFilteredTimes;
    }
    return times;
  };

  const { data, isPlaceholderData, isLoading, isFetching, isError, error } =
    useQuery({
      queryKey: [
        'dayAvailableTimes',
        formattedDay,
        employee.employeeId,
        timezone,
        duration,
        service?.onschedServiceId,
        appointment?.businessId,
        appointment?.serviceCategory,
      ],
      queryFn: getDayAvailableTimes,
      enabled: !!day && !!employee,
      staleTime: 1000 * 30, // 30 seconds
      gcTime: 1000 * 60, // 60 seconds
    });

  return {
    data: isPlaceholderData ? null : data,
    isPlaceholderData,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useDayAvailableTimes;
