import * as React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues, Image } from '../../interfaces';
import ImageUpload from './ImageUpload';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

function AreaImages({ formValues, setFormValues, nextStep, prevStep }: Props) {
  const [images, setImages] = React.useState<Image[]>([]);
  const [isValid, setIsValid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (images.length > 0 && images.length <= 6) {
      setIsValid(true);
    }
  }, [images]);

  React.useEffect(() => {
    if (loading) {
      if (formValues.areaImages && formValues.areaImages.length > 0) {
        setImages([...formValues.areaImages]);
        setIsValid(true);
      }
      setLoading(false);
    }
  }, [formValues.areaImages, loading]);

  const questionValues = {
    header: `Last Step! Attach a photo of where you want the tattoo`,
    subHeader: `Please include an image of your skin in the area as well.`,
  };

  const saveImages = () => {
    if (!isValid) {
      return;
    }

    if (images.length < 1 || images.length > 6) {
      setIsValid(false);
      setError('Please upload at least 1 image and no more than 6.');
      return;
    }

    // Set images as the formValue item
    const values = {
      areaImages: images,
    };

    setFormValues({
      ...formValues,
      ...values,
    });

    images.forEach((image, index) => {
      sessionStorage.setItem(`areaImage-${index}`, JSON.stringify(image));
    });
    sessionStorage.setItem(`areaImageCount`, JSON.stringify(images.length));

    nextStep();
  };
  return (
    <form id="ref-images">
      <FormHeader
        header={questionValues.header}
        subHeader={questionValues.subHeader}
      />
      <Box className="input-container">
        <ImageUpload
          images={images}
          setImages={setImages}
          setIsValid={setIsValid}
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        primaryAction={saveImages}
        primaryDisabled={!isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
      {error && <FormHelperText className="error">{error}</FormHelperText>}
    </form>
  );
}

export default AreaImages;
