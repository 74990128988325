import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AlertColor } from '@mui/material';
import { useDetectInstagramWebView } from '../../hooks/useDetectAndroidWebView';
import ColoredAlert from '../Global/ColoredAlert';

export interface FormHeaderAlert {
  type: AlertColor;
  title: string;
  subtitle: string;
  dismissible?: boolean;
  persistentDismissAlertKey?: string;
  animate?: boolean;
}

type Props = {
  header: string;
  subHeader?: string;
  alert?: FormHeaderAlert;
};

function FormHeader({ header, subHeader, alert }: Props) {
  const isInstagram = useDetectInstagramWebView();

  return (
    <Box className="form-header-title" style={{ gap: '20px' }}>
      {isInstagram && (
        <ColoredAlert
          type="warning"
          subtitle='It looks like you came from Instagram. If you have any issues, click the three dots in the top right corner and select "Open in Browser" to continue.'
          persistentDismissAlertKey="instagram-warning"
        />
      )}
      {alert && (
        <ColoredAlert
          type={alert.type}
          title={alert.title}
          subtitle={alert.subtitle}
          dismissible={alert.dismissible}
          animate={alert.animate}
          persistentDismissAlertKey={alert.persistentDismissAlertKey}
        />
      )}
      <Box>
        <Typography variant="h2" className="form-header">
          {header}
        </Typography>
        {subHeader && (
          <Typography
            variant="body2"
            className="form-sub-header"
            whiteSpace="pre-line">
            {subHeader}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default FormHeader;
