import { InputLabel, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  name: string;
  label?: string;
}

function InputWrapper({ children, name, label }: PropsWithChildren<Props>) {
  return (
    <Stack gap={0.5}>
      {label && (
        <InputLabel htmlFor={name}>
          <Typography variant="h5">{label}</Typography>
        </InputLabel>
      )}
      {children}
    </Stack>
  );
}

export default InputWrapper;
