import { Button, Typography } from '@mui/material';
import { important } from '../../utils/global';

interface Props {
  label: string;
  value: any;
  action: () => void;
}

export default function ReviewItem({ label, value, action }: Props) {
  return (
    <div className="review-item">
      <Typography variant="h3">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
      <Button
        className="review-link"
        id={`edit-${label}`}
        onClick={action}
        disableFocusRipple
        sx={{
          paddingRight: important('8px'),
        }}>
        Edit
      </Button>
    </div>
  );
}
