/* eslint-disable react/require-default-props */
import '../Booking.css';
import { Box, Typography } from '@mui/material';
import { Employee, Appointment, Business } from '../../../interfaces';
import PaymentSection from './PaymentSection';
import AppointmentDetailsList from '../General/AppointmentDetailsList';

type Props = {
  appointment: Appointment;
  employee: Employee;
  business: Business | undefined;
  selectedDateTime: Date;
  status: 'schedule' | 'reschedule';
  isBack?: boolean;
  backAction?: () => void;
  isReschedule?: boolean;
};

function Confirm({
  appointment,
  employee,
  business,
  selectedDateTime,
  status,
  isBack,
  backAction,
  isReschedule,
}: Props) {
  let title = '';
  if (status === 'reschedule') {
    title = `Reschedule Your Appointment`;
  } else {
    title = `Confirm your appointment`;
  }

  return (
    <Box>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {title}
        </Typography>
      </Box>

      <AppointmentDetailsList
        appointment={appointment}
        employee={employee}
        business={business}
        aptDate={selectedDateTime}
        location
        appointmentDate
        price
        cancellation
        changeDateAction={backAction}
        changeDateText="Choose a different date"
        isReschedule={isReschedule}
      />

      <PaymentSection
        appointment={appointment}
        startDateTime={selectedDateTime}
        status={status}
        employee={employee}
        isBack={isBack}
        backAction={backAction}
      />
    </Box>
  );
}

export default Confirm;
