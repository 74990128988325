import React from 'react';
import { LoadingButton } from '@mui/lab';

interface SubmitButtonProps {
  disabled?: boolean;
  loading?: boolean;
  onSubmit: () => void | Promise<void>;
}

function SubmitButton({
  disabled,
  loading,
  onSubmit,
  children,
}: React.PropsWithChildren<SubmitButtonProps>) {
  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={onSubmit}
      sx={{
        paddingY: 2,
        paddingX: 7,
        width: 'auto',
        marginBottom: 1,
        boxShadow: 'none',
      }}>
      {children}
    </LoadingButton>
  );
}

export default SubmitButton;
