import {
  ConfirmationResult as FirebaseConfirmationResult,
  RecaptchaVerifier,
} from 'firebase/auth';

export type ConfirmationResult =
  | ({ confirmationType: 'firebase' } & Omit<
      FirebaseConfirmationResult,
      'confirm'
    >)
  | { confirmationType: 'custom' };

export interface LoginValues {
  step: number;
  userPhone?: string;
  code?: String;
  loggedIn?: Boolean;
  confirmationResult?: ConfirmationResult;
}

export type SignInErrorResult = {
  error: string;
};

export type SignInSuccessResult = {
  phone: string;
  recaptcha: RecaptchaVerifier;
  confirmation: FirebaseConfirmationResult;
};

export type SignInResult = SignInErrorResult | SignInSuccessResult;

export enum VerifyChannel {
  sms = 'sms',
  call = 'call',
}
