import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ModalHeader from './ModalHeader';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'calc(100% - 32px)',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 1,
  maxWidth: 600,
};

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
  negativeAction: () => void;
  positiveAction: () => void;
  negativeText: string;
  positiveText: string;
}

export default function ConfirmationModal({
  open,
  setOpen,
  title,
  message,
  negativeAction,
  positiveAction,
  negativeText,
  positiveText,
}: Props) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <ModalHeader name={title} handleClose={handleClose} />
          <Box
            style={{
              padding: '20px 10px',
            }}>
            <Typography
              id="modal-modal-description"
              variant="body1"
              className="center">
              {message}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: negativeText ? 'space-between' : 'flex-end',
              padding: '20px 10px',
              borderTop: '1px solid #F5F5F5',
            }}>
            {negativeText && (
              <Button
                variant="outlined"
                className="secondary"
                onClick={negativeAction}>
                {negativeText}
              </Button>
            )}
            <Button
              variant="contained"
              className="primary"
              onClick={positiveAction}>
              {positiveText}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
