import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';

interface Props {
  id: string | number;
  title: string;
  description: string;
  onClick: (id: string | number) => void;
}

export default function ServiceCard({
  id,
  title,
  description,
  onClick,
}: Props) {
  const theme = useTheme();

  const createLabel = () => (
    <Box className="service-label">
      <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2" color={theme.palette.medGrey.main}>
          {description}
        </Typography>
      </div>
    </Box>
  );

  return (
    <Box onClick={() => onClick(id)}>
      <FormControlLabel
        value={id}
        control={<Radio inputProps={{ className: 'radio-service' }} />}
        label={createLabel()}
        className="service-label"
        labelPlacement="top"
      />
    </Box>
  );
}
