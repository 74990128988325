import { useContext } from 'react';
import { AppContext } from '../../context/AppContext/context';

function useUser() {
  const { state } = useContext(AppContext);

  return {
    user: state.user.data,
    isLoading: state.user.isLoading,
  };
}

export default useUser;
