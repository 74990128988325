import React from 'react';

/**
 * This hook is used to disable a button (or other element) after it is clicked, and then re-enable it after a certain amount of time.
 * */
function useStateCooldown(duration = 1000, initialDisabled = false) {
  const [disabled, setDisabled] = React.useState(initialDisabled);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (disabled) {
      timeout = setTimeout(() => {
        setDisabled(false);
      }, duration);
    }
    return () => timeout && clearTimeout(timeout);
  }, [disabled, duration]);

  return [disabled, setDisabled] as const;
}

export default useStateCooldown;
