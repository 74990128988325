/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useIdToken } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import '../App.css';
import AppointmentPage from '../components/Booking/Flows/AppointmentPage';
import Overview from '../components/Booking/Flows/Overview';
import {
  Appointment,
  Business,
  Employee,
  GeneralRequest,
  User,
} from '../interfaces';
import Login from '../components/auth/Login';
import { auth } from '../firebase';
import { getUser } from '../services/user';
import { getEmployeeById } from '../services/employee';
import { getAppointment } from '../services/appointment';
import Checkout from '../components/Booking/Flows/Checkout';
import { getCustomersAppointments } from '../services/customer';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ScheduleAndConfirm from '../components/Booking/Flows/ScheduleAndConfirm';
import ConfirmScheduled from '../components/Booking/Flows/ConfirmScheduled';
import { getBusinessById } from '../services/business';
import Waiver from '../components/Booking/Waiver/Waiver';
import useWaiver from '../hooks/waiver/useWaiver';
import { getAppointmentStatus } from '../utils/appointment';

export default function BookingPage() {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [generalRequest, setGeneralRequest] = React.useState<GeneralRequest>();

  const [employee, setEmployee] = React.useState<Employee>();
  const [business, setBusiness] = React.useState<Business>();

  const [user, setUser] = React.useState<User>();
  const [aptDate, setAptDate] = React.useState<Date>();
  const [loading, setLoading] = React.useState(true);
  const [userMatch, setUserMatch] = React.useState(false);
  const [authUser, authLoading] = useIdToken(auth);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [employeeError, setEmployeeError] = React.useState(false);
  const [appointmentsList, setAppointmentsList] =
    React.useState<Appointment[]>();

  const { needsWaiver } = useWaiver({ appointment });

  const status = getAppointmentStatus(appointment);

  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        try {
          const tempUser = await getUser().catch(() => {
            setError(true);
          });

          if (tempUser.userId) {
            const createdAt = new Date(tempUser.createdAt);
            window.heap.identify(tempUser.userId);
            window._cio.identify({
              // Required attributes — you must include at least one of the following
              id: tempUser.userId, // Unique id for the user in your application.
              email: tempUser.email, // Email of the currently signed in user.

              created_at: Math.floor(createdAt.getTime() / 1000),
              first_name: tempUser.firstName,
              last_name: tempUser.lastName,
              phone_number: tempUser.phoneNumber,
            });
          }

          setUser({
            ...tempUser,
          });

          if (appointmentId) {
            const tempAppointment = await getAppointment(appointmentId).catch(
              () => {
                setError(true);
              },
            );

            if (tempAppointment.customerId === tempUser.userId) {
              setUserMatch(true);
            }

            if (tempUser.role === 'ADMIN') {
              setUserMatch(true);
            }

            if (tempAppointment.appointmentId) {
              const tempEmployee = await getEmployeeById(
                tempAppointment.employeeId,
              ).catch(() => {
                setEmployeeError(true);
              });

              window._cio.page('Booking', {
                appointmentId: tempAppointment.appointmentId,
              });

              setAppointment({
                ...tempAppointment,
              });
              if (tempAppointment.startDateTime) {
                setAptDate(new Date(tempAppointment.startDateTime));
              }

              if (tempEmployee) {
                setEmployee({
                  ...tempEmployee,
                });
              }

              const tempBusiness = await getBusinessById(
                tempAppointment.businessId,
              ).catch(() => {
                // log error
              });

              if (tempBusiness) {
                setBusiness({
                  ...tempBusiness,
                });
              }
            } else {
              const tempBusiness = await getBusinessById(
                tempAppointment.businessId,
              ).catch(() => {
                // log error
              });

              if (tempAppointment) {
                window._cio.page('Booking', {
                  appointmentId: tempAppointment.requestId,
                });

                setGeneralRequest({
                  ...tempAppointment,
                });
              }

              if (tempBusiness) {
                setBusiness({
                  ...tempBusiness,
                });
              }
            }
          } else {
            const tempAppointments = await getCustomersAppointments();
            setAppointmentsList(tempAppointments);
          }
        } catch {
          setError(true);
        }
        setDoneLoading(true);
      }

      setLoading(false);
    }

    if (loading || (authUser && !doneLoading)) {
      fetchData();
    }
  }, [
    appointmentId,
    loading,
    appointment,
    authUser,
    user,
    employee,
    doneLoading,
  ]);

  const signout = React.useCallback(() => {
    signOut(auth);
  }, []);

  const isConsultation = appointment?.appointmentType === 'CONSULTATION';

  switch (true) {
    // Everything is loading
    case loading || authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Login" subtitle="Your Appointment" />
          <Login />
          <PorterFooter />
        </Box>
      );

    // User is authenticated, no appointment ID on page, so appointment list was loaded
    case authUser && appointmentsList && appointmentsList.length > 0:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Your Bookings" />
          <div className="appointment-list-container">
            <Typography variant="h1" style={{ marginBottom: 20 }}>
              Your Bookings
            </Typography>
            {appointmentsList?.map(appointmentRow => (
              <div
                className="appointment-link-box"
                key={appointmentRow.appointmentId}>
                <Typography variant="body1">
                  Artist: {appointmentRow.employee?.displayName}
                </Typography>
                <Typography variant="body1">
                  Appointment Status: {status}
                </Typography>
                <Typography variant="body1">
                  Tattoo Location: {appointmentRow.tattooLocation}
                </Typography>
                <Button
                  variant="contained"
                  href={`/booking/${appointmentRow.appointmentId}`}>
                  View Appointment
                </Button>
              </div>
            ))}
          </div>
          <PorterFooter />
        </Box>
      );

    // User authed, everything else is loading
    case authUser &&
      !(user && (employee || business) && (appointment || generalRequest)) &&
      !employeeError &&
      !error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Everything is loaded. Appointment status is undefined
    case authUser && appointment && !status:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Could not locate your booking. Try again or reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. User does not match customer ID of the booking they're trying to access
    case authUser && (appointment || generalRequest) && !userMatch:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              The phone number you logged in with does not match the user for
              this booking. Log out, and then enter the phone number you used to
              create this booking to access this page.
            </Typography>
            <Button
              variant="contained"
              className="next done"
              onClick={signout}
              id="sign-out">
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. Employee just initiated checkout session
    case authUser &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      appointment.checkoutSessionActive &&
      !appointment.checkoutSessionCompleted &&
      appointment.paymentMethod === 'CREDIT' &&
      userMatch &&
      user &&
      !employeeError:
      return appointment && employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Checkout" subtitle="Your appointment" />
          <Checkout
            appointment={appointment}
            employee={employee}
            business={business}
            loading={!doneLoading}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Everything is loaded. Employee just initiated checkout session
    case authUser &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      appointment.checkoutSessionActive &&
      !appointment.checkoutSessionCompleted &&
      appointment.paymentMethod !== 'CREDIT' &&
      userMatch &&
      user &&
      !employeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Checkout" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Checkout is active with a different payment method. Business must
              cancel the current checkout session, and initiate a new credit
              link checkout.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Checkout session is completed
    case authUser &&
      appointment &&
      (status === 'CONFIRMED' || status === 'SCHEDULED') &&
      !appointment.checkoutSessionActive &&
      appointment.checkoutSessionCompleted &&
      userMatch &&
      user &&
      !employeeError:
      return employee && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Your Appointment"
          />
          <Overview
            appointment={appointment!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was scheduled by employee, needs to be confirmed by user
    case authUser &&
      appointment &&
      status === 'SCHEDULED' &&
      !isConsultation &&
      aptDate &&
      userMatch &&
      !employeeError:
      // If employee is loaded, show flow JustConfirm.tsx. Otherwise loading element.
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Schedule Appointment"
          />
          <ConfirmScheduled
            appointment={appointment!}
            employee={employee}
            business={business}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was accepted by employee, user needs to schedule and confirm
    case authUser &&
      appointment &&
      status === 'ACCEPTED' &&
      user &&
      userMatch &&
      !employeeError:
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Schedule Appointment"
          />
          <ScheduleAndConfirm
            appointment={appointment!}
            employee={employee}
            business={business}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment is confirmed. Display appointment details.
    case authUser &&
      appointment &&
      (status === 'CONFIRMED' || isConsultation) &&
      userMatch &&
      user &&
      !employeeError:
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle={`Your ${isConsultation ? 'Consultation' : 'Appointment'}`}
          />
          {needsWaiver ? (
            <Box
              style={{
                flex: '1 1 0',
                marginTop: 63,
                paddingBottom: 165,
              }}>
              <Waiver
                type="appointment"
                appointment={appointment}
                business={business}
              />
            </Box>
          ) : (
            <AppointmentPage
              appointment={appointment!}
              employee={employee}
              business={business}
            />
          )}
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment was requested, rejected, or canceled
    case authUser &&
      appointment &&
      (status === 'REQUESTED' ||
        status === 'REJECTED' ||
        status === 'CANCELED' ||
        status === 'ARCHIVED') &&
      userMatch &&
      !employeeError:
      return employee && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Your Appointment"
          />
          <Overview
            appointment={appointment!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Appointment is a general request to a shop
    case authUser && generalRequest && userMatch && !employeeError:
      return business && user ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title={business.name} subtitle="Your Appointment" />
          <Overview
            appointment={generalRequest!}
            employee={employee}
            business={business}
            user={user}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    case error || employeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above. You may be logged in with
              a different account than the one you used to create the booking.
              Try logging out, and make sure to log back in with the same phone
              number you used to create the booking.
            </Typography>
            <Button
              variant="contained"
              className="next done"
              onClick={signout}
              id="sign-out">
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    default:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );
  }
}
