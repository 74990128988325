import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Employee, Appointment, Business } from '../../../interfaces';
import '../Booking.css';
import AppointmentDetailsList from '../General/AppointmentDetailsList';
import BottomActionBar from '../../Global/BottomActionBar';
import Waiver from '../Waiver/Waiver';
import Confirm from '../Confirm/Confirm';
import useWaiver from '../../../hooks/waiver/useWaiver';

type Props = {
  appointment: Appointment;
  employee: Employee;
  business: Business | undefined;
};

function ConfirmScheduled({ appointment, employee, business }: Props) {
  const { needsWaiver } = useWaiver({ appointment });

  const [step, setStep] = React.useState<'details' | 'waiver' | 'confirm'>(
    needsWaiver ? 'details' : 'confirm',
  );
  // const [progress, setProgress] = React.useState<number>(1);

  const onWaiverComplete = () => {
    setStep('confirm');
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    setStep('details');
  };

  switch (step) {
    case 'details':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: 165,
          }}>
          <Box className="form-header-title">
            <Typography variant="h2" className="form-header">
              Confirm your appointment with{' '}
              {employee.displayName || employee.user.firstName || 'your artist'}
            </Typography>
            <Typography variant="body2" className="form-sub-header">
              Review details and confirm your appointment
            </Typography>
          </Box>
          <AppointmentDetailsList
            appointment={appointment}
            employee={employee}
            business={business}
            aptDate={new Date(appointment.startDateTime!)}
            location
            appointmentDate
            price
          />
          <BottomActionBar
            primaryText="Next"
            primaryAction={() =>
              needsWaiver ? setStep('waiver') : setStep('confirm')
            }
          />
        </Box>
      );

    case 'waiver':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: 125,
          }}>
          <Waiver
            type="appointment"
            appointment={appointment}
            onSuccess={onWaiverComplete}
            handleBack={handleBack}
            business={business}
          />
        </Box>
      );

    case 'confirm':
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
          }}>
          <Confirm
            appointment={appointment}
            employee={employee}
            business={business}
            selectedDateTime={new Date(appointment.startDateTime!)}
            status="schedule"
          />
        </Box>
      );

    default:
      return (
        <Box
          style={{
            flex: '1 1 0',
            marginTop: 63,
            paddingBottom: 125,
          }}>
          <Typography variant="h1">
            There was an error loading your booking. Please reach out to our
            support team using the help icon above.
          </Typography>
        </Box>
      );
  }
}

export default ConfirmScheduled;
