import * as React from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { Choice, FormValues } from '../../interfaces';
import { getChoices } from '../../services/choices';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

const validationSchema = yup.object({
  location: yup.string().required('Please select a tatoo location'),
});

function TattooLocation({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
}: Props) {
  const [locations, setLocations] = React.useState<Choice[]>();
  const [loading, setLoading] = React.useState(true);

  const questionValues = {
    header: 'Where on your body do you want your tattoo?',
  };

  React.useEffect(() => {
    async function fetchData() {
      const tempLocations = await getChoices('PLACEMENT').catch(() => {});
      tempLocations.sort((a: Choice, b: Choice) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) {
          return -1;
        }
        if (textA > textB) {
          return 1;
        }
        return 0;
      });
      setLocations(tempLocations);
      setLoading(false);
    }
    if (loading) {
      fetchData();
    }
  });

  const formik = useFormik({
    initialValues: {
      location: formValues.location,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('location', values.location!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="tattoo-location">
      <FormHeader header={questionValues.header} />

      <Box className="input-container">
        {loading ? <Typography variant="inherit">Loading...</Typography> : null}
        {!loading && !locations ? (
          <Typography variant="inherit">
            Error loading tattoo locations
          </Typography>
        ) : null}
        {locations && locations.length > 0 ? (
          <TextField
            id="location"
            select
            name="location"
            label="Tattoo Location"
            value={formik.values.location}
            onChange={formik.handleChange}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}>
            {locations.map(location => (
              <MenuItem
                key={location.name}
                value={location.name}
                className="tattoo-location-choice">
                {location.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Box>

      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default TattooLocation;
