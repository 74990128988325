/* eslint-disable react/require-default-props */
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { AvailableTimes } from '../../../../interfaces';

type Props = {
  time: AvailableTimes;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setStep?: React.Dispatch<React.SetStateAction<string>>;
  nextStep?: () => void;
};

export default function Time({
  time,
  setSelectedDateTime,
  setStep,
  nextStep,
}: Props) {
  const dateTime = new Date(time.startDateTime);

  function clickHandle(datetime: Date) {
    setSelectedDateTime(datetime);
    if (setStep) {
      setStep('confirm');
    } else if (nextStep) {
      nextStep();
    }
    window.scrollTo(0, 0);
  }

  return (
    <div
      role="presentation"
      className="time-item"
      onClick={() => {
        clickHandle(dateTime);
      }}>
      <Typography variant="h3">{time.displayTime}</Typography>
    </div>
  );
}
