import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getBusinessByUrlPath } from '../../services/business';

function useGetBusiness(path?: string) {
  const { shopUrl } = useParams();
  const newPath = path || shopUrl;

  const {
    data: business,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ['business-path', newPath],
    queryFn: async () => {
      const tempBusiness = await getBusinessByUrlPath(newPath!);
      return tempBusiness || null;
    },
    enabled: !!newPath,
  });

  return {
    business,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useGetBusiness;
