import * as React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';
import { useTheme } from '@mui/material';
import STRIPE_PK from '../../../stripe';
import NewPaymentCheckoutForm from './NewPaymentCheckoutForm';
import {
  Appointment,
  AppointmentCheckoutSession,
  Business,
} from '../../../interfaces';

const stripePromise = loadStripe(STRIPE_PK);

interface Props {
  appointment: Appointment;
  business: Business | undefined;
  checkoutSession: AppointmentCheckoutSession | null;
  tipPriceCents: number;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  disabledCheckout?: boolean;
}

export default function AddNewPaymentMethod({
  appointment,
  business,
  checkoutSession,
  tipPriceCents,
  setSubmitting,
  setSuccess,
  setErrorMessage,
  disabledCheckout,
}: Props) {
  const theme = useTheme();

  const appearance: Appearance = {
    labels: 'floating' as 'floating',
    variables: {
      colorPrimary: theme.palette.primary.main,
      colorDanger: theme.palette.primary.main,
      colorBackground: '#ffffff',
      colorText: theme.palette.darkGrey.main,
      fontFamily: 'Montserrat',
      borderRadius: '5px',
      spacingUnit: '4px',
      fontSizeBase: '16px',
      fontWeightBold: '700',
      fontWeightLight: '400',
      colorTextPlaceholder: theme.palette.outlineGrey.main,
    },
    rules: {
      '.Label': {
        color: theme.palette.outlineGrey.main,
      },
      '.Input': {
        borderColor: theme.palette.outlineGrey.main,
      },
      '.Input:focus': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
      '.Input--focused': {
        borderColor: theme.palette.darkGrey.main,
        boxShadow: 'none',
        outline: '0px',
        borderWidth: '1.5px',
      },
    },
  };

  const fonts = [
    { cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400' },
  ];

  const options: StripeElementsOptions = {
    clientSecret: checkoutSession?.paymentIntent?.client_secret,
    appearance,
    fonts,
  };

  return (
    <div className="add-new-payment">
      {options.clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={options}
          key={options.clientSecret}>
          <NewPaymentCheckoutForm
            checkoutSession={checkoutSession}
            business={business}
            setErrorMessage={setErrorMessage}
            setSubmitting={setSubmitting}
            setSuccess={setSuccess}
            appointment={appointment}
            tipAmtCents={tipPriceCents}
            disabledCheckout={disabledCheckout}
          />
        </Elements>
      ) : null}
    </div>
  );
}
