/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { Box, Grid2, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { PropsOf } from '@emotion/react';
import { CSSProperties } from 'react';
import TextInput from '../../Form/base/TextInput';
import { important } from '../../../utils/global';
import PhoneInput from '../../Form/base/PhoneInput';
import BirthdayInput from '../../Form/base/BirthdayInput';
import SmallModal from '../SmallModal';

type Input =
  | ({
      inputType: 'text' | 'email';
    } & PropsOf<typeof TextInput>)
  | ({
      inputType: 'phone';
    } & PropsOf<typeof PhoneInput>)
  | ({
      inputType: 'birthday';
    } & PropsOf<typeof BirthdayInput>);

export type InputRow<Values extends FormikValues> = (Input & {
  name: keyof Values;
})[];

export type FormData<Values extends FormikValues = any> = {
  title?: string;
  validationSchema?: any | (() => any);
  initialValues: Values;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void | Promise<any>;
  submitButtonText?: string;
  inputRows: InputRow<Values>[];
};

const formStyle: CSSProperties = {
  width: '100%',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
};

interface Props {
  open: boolean;
  onClose: () => void;
  form: FormData | null;
}

function FormSmallModal({ open, onClose, form }: Props) {
  if (!form) return null;

  return (
    <SmallModal open={open} onClose={onClose} title={form.title}>
      {form && (
        <Formik
          enableReinitialize
          initialValues={form.initialValues}
          validationSchema={form.validationSchema}
          onSubmit={form.onSubmit}>
          {({ isSubmitting, isValid, isValidating, dirty }) => (
            <Form style={formStyle}>
              <Stack p={2} width="100%" gap={2} boxSizing="border-box">
                {form.inputRows.map((inputRow, index) => (
                  <Grid2
                    container
                    columns={inputRow.length}
                    gap={2}
                    flexWrap="nowrap"
                    key={index}
                    maxWidth="100%"
                    width="100%">
                    {inputRow.map(({ inputType, ...input }, inputIndex) => {
                      if (inputType === 'text' || inputType === 'email') {
                        return (
                          <Grid2 key={inputIndex} size={1} width="100%">
                            <TextInput
                              type={inputType}
                              {...(input as PropsOf<typeof TextInput>)}
                            />
                          </Grid2>
                        );
                      }
                      if (inputType === 'phone') {
                        return (
                          <Grid2 key={inputIndex} size={1} width="100%">
                            <PhoneInput
                              {...(input as PropsOf<typeof PhoneInput>)}
                            />
                          </Grid2>
                        );
                      }
                      if (inputType === 'birthday') {
                        return (
                          <Grid2 key={inputIndex} size={1} width="100%">
                            <BirthdayInput
                              {...(input as PropsOf<typeof BirthdayInput>)}
                            />
                          </Grid2>
                        );
                      }
                      return null;
                    })}
                  </Grid2>
                ))}
              </Stack>
              <Box
                p={2}
                position="sticky"
                bottom={0}
                zIndex={1}
                boxSizing="border-box"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                borderTop={theme =>
                  `0.5px solid ${theme.palette.lightOutlineGrey.main}`
                }
                bgcolor="background.paper">
                <LoadingButton
                  type="submit"
                  className="primary"
                  disabled={isSubmitting || !isValid || isValidating || !dirty}
                  variant="contained"
                  color="primary"
                  sx={{
                    height: '40px',
                    whiteSpace: 'nowrap',
                    borderRadius: '8px',
                    fontSize: '0.875rem',
                    paddingX: important('3rem'),
                    ':disabled': {
                      border: important('none'),
                    },
                  }}
                  loading={isSubmitting}>
                  <span>{form.submitButtonText ?? 'Update'}</span>
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </SmallModal>
  );
}

export default FormSmallModal;
