import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

function MedicalHistory({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
}: Props) {
  const questionValues = {
    header:
      'Do you have any allergies or any important medical history that your artist should know about?',
  };

  const formik = useFormik({
    initialValues: {
      medicalHistory: formValues.medicalHistory,
    },
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      if (values.medicalHistory) {
        sessionStorage.setItem('medicalHistory', values.medicalHistory!);
      }
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="medical-history">
      <FormHeader header={questionValues.header} />
      <Box className="input-container">
        <TextField
          id="medicalHistory"
          name="medicalHistory"
          value={formik.values.medicalHistory}
          onChange={formik.handleChange}
          multiline
          placeholder="Please enter any allergies you have or any relevant prior medical history."
          minRows={3}
          className="textarea"
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default MedicalHistory;
