import { Stack } from '@mui/material';
import PaymentTableRow from './PaymentTableRow';
import PaymentTotalRow from './PaymentTotalRow';

interface Props {
  depositAmtCents: number;
  serviceFeeCents: number;
  salesTaxCents: number;
  status: string;
}

export default function PaymentTable({
  depositAmtCents,
  serviceFeeCents,
  salesTaxCents,
  status,
}: Props) {
  const depositAmtDollars = depositAmtCents / 100;
  const taxAndFeesDollars = ((salesTaxCents || 0) + serviceFeeCents) / 100;

  const totalPrice = depositAmtDollars + taxAndFeesDollars;

  const depositText = `$${depositAmtDollars.toFixed(2)}`;
  const taxAndFeesText = `$${taxAndFeesDollars.toFixed(2)}`;
  const totalText = `$${totalPrice.toFixed(2)}`;

  let label = 'Deposit Fee (paid to artist)'; // Default label
  if (status === 'reschedule') {
    label = 'Rescheduling Fee (paid to artist)';
  }

  return (
    <div className="payment-details">
      <Stack spacing={1.25} className="payment-stack">
        <PaymentTableRow label={label} amount={depositText} />
        {!!taxAndFeesDollars && (
          <PaymentTableRow
            label={salesTaxCents ? 'Taxes & Fees' : 'Service Fee'}
            amount={taxAndFeesText}
          />
        )}
        <PaymentTotalRow label="Total due today" amount={totalText} />
      </Stack>
    </div>
  );
}
