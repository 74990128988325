import { Roles, Users } from '@prisma/client';

export const isUserEmployeeOrAdmin = (user: Users | Roles) => {
  const role = typeof user === 'string' ? user : user?.role;
  return (
    [Roles.EMPLOYEE, Roles.SHOP_MANAGER, Roles.ADMIN] as Roles[]
  ).includes(role);
};

export const isUserEmployee = (user: Users | Roles) => {
  const role = typeof user === 'string' ? user : user?.role;
  return ([Roles.EMPLOYEE, Roles.SHOP_MANAGER] as Roles[]).includes(role);
};
