import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import useDetectKeyboardOpen from '../../hooks/useDetectKeyboardOpen';
import { useDetectAndroidWebView } from '../../hooks/useDetectAndroidWebView';

export default function PorterFooter() {
  const theme = useTheme();
  const desktop = useMediaQuery('(min-width:600px)');
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isWebView = useDetectAndroidWebView();
  const hide = isWebView && isKeyboardOpen;

  return (
    <Box
      style={{
        width: !desktop ? '100%' : 568,
        borderTopColor: theme.palette.lightGrey.main,
        borderBottomColor: theme.palette.lightGrey.main,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopStyle: 'solid',
        borderBottomStyle: 'solid',
        position: 'fixed',
        bottom: 0,
        display: hide ? 'none' : 'flex',
        justifyContent: 'center',
        padding: '10px 0px',
        background: '#ffffff',
      }}>
      <Typography variant="body2" color={theme.palette.medGrey.main}>
        Powered by Porter
      </Typography>
    </Box>
  );
}
