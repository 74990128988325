import * as React from 'react';
import {
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { WaiverOptions } from '@prisma/client';
import { WaiverSectionProps } from '../../../types/waivers';

interface Props {
  options?: Pick<WaiverOptions, 'id' | 'type' | 'order' | 'text'>[];
  waiverForm: WaiverSectionProps['waiverForm'];
  type: 'acknowledgments' | 'medicalHistory';
}

export default function WaiverOptionsList({
  options,
  waiverForm,
  type,
}: Props) {
  const theme = useTheme();

  const sortedOptions = options?.sort(
    (a, b) => (a.order ?? 0) - (b.order ?? 0),
  );

  const handleCheckboxChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      waiverForm.set(prev => ({
        ...prev,
        [type]: {
          ...prev[type],
          [id]: isChecked,
        },
      }));
    };

  return (
    <Stack
      justifyContent="start"
      boxSizing="border-box"
      gap={1}
      sx={{
        '.MuiFormControlLabel-root': {
          margin: '0 !important',
        },
      }}>
      {!!sortedOptions &&
        sortedOptions.map(option =>
          option.type === 'CHECKBOX' ? (
            <FormControlLabel
              key={option.id}
              sx={{
                boxSizing: 'border-box',
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={!!waiverForm.value[type][option.id]}
                  onChange={handleCheckboxChange(option.id!)}
                  sx={{
                    padding: 0,
                    mr: 0.5,
                  }}
                />
              }
              label={<Typography variant="body2">{option.text}</Typography>}
            />
          ) : (
            <Stack key={option.id} spacing={1}>
              <Typography variant="h5" whiteSpace="pre-line">
                {option.text}
              </Typography>

              <TextField
                id={option.id}
                name={option.id}
                value={waiverForm.value[type][option.id] as string}
                onChange={e =>
                  waiverForm.set(prev => ({
                    ...prev,
                    [type]: {
                      ...prev[type],
                      [option.id!]: e.target.value,
                    },
                  }))
                }
                multiline
                minRows={3}
                className="textarea"
                style={{
                  width: '100%',
                }}
                inputProps={{
                  style: {
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.body2.fontSize,
                  },
                }}
              />
            </Stack>
          ),
        )}
    </Stack>
  );
}
