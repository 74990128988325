import type Stripe from 'stripe';
import { useQuery } from '@tanstack/react-query';
import { getPaymentMethods } from '../../services/customer';

function usePaymentMethods({
  onSuccess,
}: {
  onSuccess?: (paymentMethods: Stripe.PaymentMethod[]) => void;
}) {
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: async () => {
      const paymentMethods = await getPaymentMethods();
      if (paymentMethods) onSuccess?.(paymentMethods.data);
      return paymentMethods.data as Stripe.PaymentMethod[];
    },
  });

  return {
    paymentMethods: data,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  };
}

export default usePaymentMethods;
