import API_URL from '../apiconfig';
import axios from './api';

export async function validateDiscountCode(
  discountCode: string,
  appointmentId: string,
) {
  try {
    const response = await axios.get(
      `${API_URL}/v1/discounts/validate/${discountCode}`,
      {
        params: {
          appointmentId,
        },
      },
    );

    return !!response.data?.result?.isValid;
  } catch (error) {
    throw new Error('Cannot validate discount code');
  }
}
