/* eslint-disable react/no-array-index-key */
import { DetailItem } from '../../../interfaces';
import DetailRow from './DetailRow';

type Props = {
  detailItems: DetailItem[];
};

export default function DetailList({ detailItems }: Props) {
  return (
    <div className="detail-list">
      {detailItems.map((detailItem, index) => (
        <DetailRow
          type={detailItem.type}
          label={detailItem.label}
          values={detailItem.values}
          key={index}
        />
      ))}
    </div>
  );
}
