import { useParams } from 'react-router-dom';
import { Business } from '../../interfaces';

function useGetServiceFromParam(business?: Business | null) {
  const { serviceId } = useParams();
  const numericServiceId = Number(serviceId);

  const service = business?.services?.find(
    s => s.serviceId === numericServiceId,
  );

  return service;
}

export default useGetServiceFromParam;
