import { Box, Stack, Typography, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import { Service } from '../../interfaces';
import ServiceCard from './ServiceCard';
import { DEFAULT_TATTOO_SERVICE_ID } from '../../constants/global';
import { ServiceBookingListItem } from '../../types/services';

interface Props {
  header: string;
  subHeader?: string;
  services?: Service[];
  showTattoo?: boolean;
  onClick: (id: string | number) => void;
}

const formatServiceCategory = (category: string) =>
  category
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());

export default function ServicesBookingList({
  header,
  subHeader,
  services,
  showTattoo,
  onClick,
}: Props) {
  const [selectedCategory, setSelectedCategory] = useState(0);

  const bookableServices = services?.filter(
    service => service.enableDirectBookings,
  );

  if (!bookableServices?.length) return null;

  const groupedServices = bookableServices.reduce(
    (acc, service) => {
      const category = service.serviceCategory || 'Other';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(service);
      return acc;
    },
    {} as Record<string, ServiceBookingListItem[]>,
  );

  if (showTattoo) {
    groupedServices.Tattoo = [
      {
        serviceId: DEFAULT_TATTOO_SERVICE_ID,
        serviceName: 'Tattoo Session',
        serviceDescription:
          'Click here to submit a booking request and to schedule your tattoo appointment.',
        serviceCategory: 'Tattoo',
        enableDirectBookings: true,
      },
    ];
  }

  const prioritizedCategories = ['Tattoo', 'Piercing', 'Permanent Makeup'];
  const otherCategories = Object.keys(groupedServices).filter(
    category =>
      !prioritizedCategories.includes(category) &&
      groupedServices[category].length > 0,
  );

  const categories = [
    ...prioritizedCategories.filter(
      category => groupedServices[category]?.length > 0,
    ),
    ...otherCategories,
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedCategory(newValue);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      }}>
      <div>
        <Typography variant="h2">{header}</Typography>
        {subHeader && <Typography variant="body2">{subHeader}</Typography>}
      </div>

      <Tabs
        value={selectedCategory}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Service Categories">
        {categories.map((category, index) => (
          <Tab key={category} label={formatServiceCategory(category)} />
        ))}
      </Tabs>

      <Stack gap={2}>
        {groupedServices[categories[selectedCategory]].map(service => (
          <ServiceCard
            id={service.serviceId}
            title={service.serviceName}
            description={service.serviceDescription}
            onClick={onClick}
            key={service.serviceId}
          />
        ))}
      </Stack>
    </Box>
  );
}
