import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

let firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCZM0XQuH0X5JxILw0hxR8zOtLUtYfadYA',
  authDomain: 'getinked-dev.firebaseapp.com',
  projectId: 'getinked-dev',
  storageBucket: 'getinked-dev.appspot.com',
  messagingSenderId: '770416014343',
  appId: '1:770416014343:web:53ace47045252845e926a2',
  measurementId: '',
};

if (window.location.hostname === 'web.getporter.io') {
  firebaseConfig = {
    apiKey: 'AIzaSyDvtszDKNkPHuTfBD2b8AE7hz94BkNs98E',
    authDomain: 'bamboo-artwork-372213.firebaseapp.com',
    projectId: 'bamboo-artwork-372213',
    storageBucket: 'bamboo-artwork-372213.appspot.com',
    messagingSenderId: '874747184872',
    appId: '1:874747184872:web:9122525e354f289423ab5d',
    measurementId: 'G-3B6L7WXNH0',
  };
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
