import '../../Booking/Booking.css';
import { Employee, Service } from '../../../interfaces';
import AppointmentDetailsList from '../../Booking/General/AppointmentDetailsList';
import PageLayout from '../../Global/PageLayout';
import { BookingSteps } from '../../../constants/services';

type Props = {
  service: Service;
  employee: Employee;
  selectedDateTime: Date;
  duration: number;
  price: number;
  prevStep: () => void;
  backToStep: (stepName: BookingSteps) => void;
};

function ConfirmPiercing({
  service,
  employee,
  selectedDateTime,
  duration,
  price,
  prevStep,
  backToStep,
}: Props) {
  const title = 'Confirm your appointment';

  return (
    <PageLayout header={title} isNextSubmit onBack={prevStep}>
      <AppointmentDetailsList
        service={service}
        employee={employee}
        aptDate={selectedDateTime}
        duration={duration}
        piercingPrice={price}
        location
        appointmentDate
        serviceType
        price
        changeDateAction={() => backToStep(BookingSteps.CALENDAR)}
        changeDateText="Choose a different date"
      />
    </PageLayout>
  );
}

export default ConfirmPiercing;
