import API_URL from '../apiconfig';
import { PocketflowsUnsubscribeFormEmbed } from '../types/marketing';
import axios from './api';

export async function createUnsubscribeFormEmbed(sourceCustomerId: string) {
  try {
    const embed = await axios.post(
      `${API_URL}/v1/marketing/${sourceCustomerId}/unsubscribe-embed`,
    );

    return embed.data.result as PocketflowsUnsubscribeFormEmbed;
  } catch (error) {
    throw new Error('Cannot create unsubscribe form embed');
  }
}
