import React from 'react';
import {
  List,
  ListItem,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import BottomDrawer from '../Global/BottomDrawer';
import { sxToObject } from '../../utils/global';
import useVerifyPhoneNumber from '../../hooks/global/useVerifyPhoneNumber';
import { ConfirmationResult, VerifyChannel } from '../../types/auth';

interface Props extends React.ComponentProps<typeof BottomDrawer> {
  phoneNumber: string;
  onSuccess?: (result: ConfirmationResult) => void | Promise<void>;
  isSmsDisabled?: boolean;
  isCallDisabled?: boolean;
}

const LIST_SX: SxProps<Theme> = {
  px: 0,
  py: 1,
};

const BUTTON_SX: SxProps<Theme> = theme => ({
  fontSize: theme.typography.body1.fontSize,
  fontWeight: 600,
});

function ResendDrawer({
  phoneNumber,
  onSuccess,
  isSmsDisabled,
  isCallDisabled,
  ...bottomDrawerProps
}: Props) {
  const { onClose } = bottomDrawerProps;

  const theme = useTheme();

  const { sendVerificationMutation } = useVerifyPhoneNumber({
    onSendSuccess: async response => {
      await onSuccess?.({
        confirmationType: 'custom',
        ...response,
      });
      toast.success('Code has been re-sent');
      onClose();
    },
    onSendError: () => {
      toast.error('Failed to send code. Please try again soon.');
    },
  });

  const resend = async (channel: VerifyChannel) => {
    sendVerificationMutation.mutate({ phoneNumber, channel });
  };

  return (
    <BottomDrawer {...bottomDrawerProps}>
      <Stack spacing={1}>
        <Typography variant="h3" component="p">
          Resend verification code
        </Typography>
        <Typography variant="body2">to {phoneNumber}</Typography>
      </Stack>

      <List
        sx={{
          listStyleType: 'disc',
          '& .MuiListItem-root': {
            display: 'list-item',
          },
          pl: 2,
        }}>
        <ListItem sx={LIST_SX}>
          <Typography variant="body2">
            Make sure your phone number is correct
          </Typography>
        </ListItem>
        <ListItem sx={LIST_SX}>
          <Typography variant="body2">
            Check your internet connection
          </Typography>
        </ListItem>
        <ListItem sx={LIST_SX}>
          <Typography variant="body2">Enter the most recent code</Typography>
        </ListItem>
      </List>

      <Stack spacing={1.5} width="100%">
        <LoadingButton
          disabled={sendVerificationMutation.isPending || isSmsDisabled}
          loading={
            sendVerificationMutation.isPending &&
            sendVerificationMutation.variables.channel === 'sms'
          }
          variant="base"
          sx={BUTTON_SX}
          onClick={() => resend(VerifyChannel.sms)}>
          <span>Text Me</span>
        </LoadingButton>
        <LoadingButton
          disabled={sendVerificationMutation.isPending || isCallDisabled}
          loading={
            sendVerificationMutation.isPending &&
            sendVerificationMutation.variables.channel === 'call'
          }
          variant="light"
          sx={{
            ...sxToObject(BUTTON_SX, theme),
            color: theme.palette.medGrey.main,
          }}
          onClick={() => resend(VerifyChannel.call)}>
          <span>Call Me</span>
        </LoadingButton>
      </Stack>
    </BottomDrawer>
  );
}

export default ResendDrawer;
