import { Typography } from '@mui/material';

interface Props {
  label: string;
  amount: string;
}

export default function PaymentTotalRow({ label, amount }: Props) {
  return (
    <div className="payment-row payment-total">
      <Typography variant="h3" style={{ textTransform: 'uppercase' }}>
        {label}
      </Typography>
      <Typography variant="h3">{amount}</Typography>
    </div>
  );
}
