import { addDays, addMilliseconds, format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { Trip } from '../interfaces';

export function getAllTripDates(trips: Trip[]) {
  const newDays: string[] = [];

  trips.forEach(trip => {
    const localTripStart = new Date(trip.startDateTime);
    const localTripEnd = new Date(trip.endDateTime);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneOffset = getTimezoneOffset(userTimezone, localTripStart);

    let tripStart = addMilliseconds(localTripStart, -1 * timezoneOffset);
    const tripEnd = addMilliseconds(localTripEnd, -1 * timezoneOffset);

    while (tripStart <= tripEnd) {
      const loopDateString = format(tripStart, 'yyyy-MM-dd');
      newDays.push(loopDateString);
      tripStart = addDays(tripStart, 1);
    }
  });

  return newDays;
}

export function getCityTripDates(city: string, trips: Trip[]) {
  const newDays: string[] = [];

  trips.forEach(trip => {
    if (trip.city === city) {
      const localTripStart = new Date(trip.startDateTime);
      const localTripEnd = new Date(trip.endDateTime);
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezoneOffset = getTimezoneOffset(userTimezone, localTripStart);

      let tripStart = addMilliseconds(localTripStart, -1 * timezoneOffset);
      const tripEnd = addMilliseconds(localTripEnd, -1 * timezoneOffset);

      while (tripStart <= tripEnd) {
        const loopDateString = format(tripStart, 'yyyy-MM-dd');
        newDays.push(loopDateString);
        tripStart = addDays(tripStart, 1);
      }
    }
  });

  return newDays;
}
