import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { RecaptchaVerifier } from 'firebase/auth';
import { toast } from 'react-toastify';
import { signIn } from '../../utils/auth';
import { SignInResult } from '../../types/auth';
import { auth } from '../../firebase';
import { isNil } from '../../utils/global';

interface Params {
  onValidPhone?: (phone: string) => void;
  onMutate?: (phone: string | undefined) => void;
  onError?: (message: string) => void;
  onSuccess?: (response: SignInResult) => void;
}

function useSignIn({ onError, onMutate, onSuccess, onValidPhone }: Params) {
  const onSignIn = React.useCallback(
    async (phone: string | undefined) => {
      const recaptchaId = `recaptcha-${Date.now()}`;
      const recaptchaElement = document.createElement('div');
      recaptchaElement.id = recaptchaId;
      document.body.appendChild(recaptchaElement);

      const recaptcha = new RecaptchaVerifier(auth, recaptchaId, {
        size: 'invisible',
      });
      const widgetId = await recaptcha.render();

      if (isNil(widgetId)) throw new Error('Recaptcha not initialized');

      const result = await signIn(phone, recaptcha, {
        onValidPhone,
      });

      try {
        recaptcha.clear();
        document.body.removeChild(recaptchaElement);
      } catch {
        // Do nothing
      }

      return result;
    },
    [onValidPhone],
  );

  const mutation = useMutation({
    mutationFn: onSignIn,
    onMutate,
    onError: (error: Error) => {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV === 'development') console.error(error);
      if ('code' in error) {
        switch (error.code) {
          case 'auth/too-many-requests':
            toast.error('Too many requests. Please try again later.');
            break;
          case 'auth/invalid-phone-number':
            toast.error('The phone number is invalid.');
            break;
          case 'auth/cors-unsupported':
            toast.error(
              'This browser is not supported. Please try another browser.',
            );
            break;
          default:
            toast.error('Something went wrong. Please try again.');
            break;
        }
      }
      onError?.(error.message);
    },
    onSuccess,
  });

  return mutation;
}

export default useSignIn;
