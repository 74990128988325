/* eslint-disable react/require-default-props */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { Employee } from '../../interfaces';

type Props = {
  employee: Employee;
  shopUrl?: string;
  handleClick?: (employeeId: string) => void;
};

function ArtistRow({ employee, shopUrl, handleClick }: Props) {
  const navigate = useNavigate();
  const [employeeName, setEmployeeName] = React.useState<string>();

  React.useEffect(() => {
    if (employee) {
      const tempName =
        employee?.displayName ||
        `${employee?.user.firstName} ${employee?.user.lastName}`;

      setEmployeeName(tempName);
    }
  }, [employee]);

  const clickAction = (employeeId: string) => {
    if (shopUrl && !handleClick) {
      navigate(`/shop/${shopUrl}/${employee.urlPath}`);
    } else if (handleClick) {
      handleClick(employeeId);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '20px',
        paddingBottom: '20px',
        borderBottom: 1,
        borderColor: 'lightGrey.main',
      }}
      onClick={() => clickAction(employee.employeeId)}>
      <img
        className="artist-thumbnail"
        src={employee?.profileImage}
        alt="Aritst profile"
        style={{
          width: 75,
          height: 75,
        }}
      />
      <Typography variant="body1" style={{ flexGrow: 1 }}>
        {employeeName}
      </Typography>
      <ChevronRightIcon sx={{ color: 'darkGrey.main' }} />
    </Box>
  );
}

export default ArtistRow;
