import { MetricType } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: MetricType) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      ({ onCLS, onINP, onFCP, onLCP, onTTFB, onFID }) => {
        onFCP(onPerfEntry);
        onLCP(onPerfEntry);
        onCLS(onPerfEntry);
        onFID(onPerfEntry);
        onTTFB(onPerfEntry);
        onINP(onPerfEntry);
      },
    );
  }
};

export default reportWebVitals;
