import React from 'react';

/**
 *
 * useDetectAndroidWebView
 *
 * @returns {boolean} isWebView
 */
export function useDetectAndroidWebView() {
  const isWebView = React.useMemo(() => {
    const { userAgent } = navigator;
    return userAgent.includes('wv') || userAgent.includes('WebView');
  }, []);

  return isWebView;
}

export function useDetectInstagramWebView() {
  const isWebView = React.useMemo(() => {
    const { userAgent } = navigator;
    return userAgent.includes('instagram') || userAgent.includes('Instagram');
  }, []);

  return isWebView;
}
