/* eslint-disable react/require-default-props */
import * as React from 'react';
import { format } from 'date-fns';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Box, useMediaQuery } from '@mui/material';
import Time from './Time';
import { Appointment, Employee, Service } from '../../../../interfaces';
import BottomActionBar from '../../../Global/BottomActionBar';
import useDayAvailableTimes from '../../../../hooks/appointments/useDayAvailableTimes';

type Props = {
  appointment?: Appointment;
  selectedDay: Date;
  setDayIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  duration: number;
  employee: Employee;
  timezone: string;
  setSelectedDateTime: React.Dispatch<React.SetStateAction<Date>>;
  setStep?: React.Dispatch<React.SetStateAction<string>>;
  nextStep?: () => void;
  service?: Service;
};

export default function TimeList({
  appointment,
  selectedDay,
  setDayIsSelected,
  duration,
  employee,
  timezone,
  setSelectedDateTime,
  setStep,
  nextStep,
  service,
}: Props) {
  const desktop = useMediaQuery('(min-width:600px)');

  const dayHeaderFormat = 'eeee, MMMM d';

  const {
    data: availableTimes,
    isFetching,
    isError,
  } = useDayAvailableTimes({
    appointment: appointment ?? null,
    day: selectedDay,
    duration,
    employee,
    selectedDay,
    service,
    timezone,
  });

  return (
    <Grid item xs={12} sm={12} md={4} className="time-box">
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {format(selectedDay, dayHeaderFormat)}
        </Typography>
        <Typography variant="body2" className="form-sub-header">
          All times in {timezone}
        </Typography>
      </Box>
      <Stack spacing={1.25} className="time-stack">
        <Typography
          variant="h3"
          style={{ marginBottom: 10, textAlign: 'center' }}>
          Select a time
        </Typography>
        {availableTimes && !isFetching ? (
          availableTimes.map(time => (
            <Time
              time={time}
              setSelectedDateTime={setSelectedDateTime}
              key={time.startDateTime}
              setStep={setStep}
              nextStep={nextStep}
            />
          ))
        ) : (
          <Typography align="center">Loading...</Typography>
        )}
        {availableTimes && availableTimes.length === 0 && !isFetching ? (
          <Typography align="center">
            No available times on this day. Please select a different day
          </Typography>
        ) : null}
        {isError ? (
          <Typography align="center">
            Error loading available times on this day.
          </Typography>
        ) : null}
      </Stack>
      {!desktop && (
        <BottomActionBar
          secondaryAction={() => setDayIsSelected(false)}
          secondaryText="Back"
        />
      )}
    </Grid>
  );
}
