import API_URL from '../apiconfig';
import {
  Appointment,
  User,
  FormValues,
  ServiceFormValues,
} from '../interfaces';
import axios from './api';

interface CustomerPayload {
  firstName: string;
  lastName: string;
  dob: Date | undefined;
  email: string;
  igHandle?: string;
  medicalHistory?: string;
  pronouns?: string;
}

export async function createUpdateCustomer(
  formValues: FormValues | ServiceFormValues,
  user: User,
) {
  try {
    const customerPayload: CustomerPayload = {
      firstName: formValues.firstName || '',
      lastName: formValues.lastName || '',
      email: formValues.email || '',
      dob:
        (formValues as FormValues).birthday ||
        (formValues as ServiceFormValues).dob,
    };

    if ((formValues as FormValues).medicalHistory) {
      customerPayload.medicalHistory = (
        formValues as FormValues
      ).medicalHistory;
    }

    if ((formValues as FormValues).igHandle) {
      customerPayload.igHandle = (formValues as FormValues).igHandle;
    }

    if ((formValues as FormValues).pronouns) {
      customerPayload.pronouns = (formValues as FormValues).pronouns;
    }

    let { userId } = user;
    if (!userId && user.result) {
      userId = user.result.userId;
    }

    const customerResponse = await axios.patch(
      `${API_URL}/v1/customers/${userId}`,
      customerPayload,
    );

    return customerResponse;
  } catch (error) {
    throw new Error('Cannot update customer');
  }
}

export async function getCustomersAppointments() {
  try {
    const appointments = await axios.get(`${API_URL}/v1/appointments?limit=25`);

    return appointments.data.result as Appointment[];
  } catch (error) {
    throw new Error('Cannot get customers appointments');
  }
}

export async function getPaymentMethods() {
  try {
    const paymentMethods = await axios.get(
      `${API_URL}/v1/customers/payment-methods`,
    );

    return paymentMethods.data.result;
  } catch (error) {
    throw new Error('Cannot get customers payment methods');
  }
}
