/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Business } from '../interfaces';
import Form from '../components/Form/Form';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import ProgressBar from '../components/EmployeeHeader/ProgressBar';
import { getBusinessByUrlPath } from '../services/business';

export default function GeneralRequest() {
  const { shopUrl } = useParams();

  const [loading, setLoading] = React.useState(true);

  const [business, setBusiness] = React.useState<Business>();

  const [progress, setProgress] = React.useState('tattoo details');
  const [step, setStep] = React.useState(1);

  const [businessError, setBusinessError] = React.useState(false);

  React.useEffect(() => {
    if (sessionStorage.getItem('progress') !== null) {
      setProgress(sessionStorage.getItem('progress')!);
    }
  }, []);

  React.useEffect(() => {
    if (sessionStorage.getItem('step') !== null) {
      setStep(JSON.parse(sessionStorage.getItem('step')!));
    }
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      const tempBusiness = await getBusinessByUrlPath(shopUrl).catch(() => {
        setBusinessError(true);
      });

      if (tempBusiness) {
        window._cio.page('General Request', {
          shopName: tempBusiness.name,
        });

        setBusiness({
          ...tempBusiness,
        });
      }

      setLoading(false);
    }

    if (loading) {
      fetchData();
    }
  }, [loading, shopUrl]);

  switch (true) {
    // Everything is loading
    case loading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // Loaded, no open request, proceed with form
    case !loading && !businessError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={business?.name}
            subtitle="Booking Request"
            step={step}
          />
          <ProgressBar
            progress={progress}
            step={step}
            intakeQuestions={business?.intakeQuestions}
          />
          <Form
            progress={progress}
            setProgress={setProgress}
            step={step}
            setStep={setStep}
            business={business}
            intakeQuestions={business?.intakeQuestions}
          />
          <PorterFooter />
        </Box>
      );

    case businessError:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            There was an error loading this shop&apos;s page. Please double
            check that you&apos;ve entered the URL in correctly, and if the
            error persists, reach out to our support team using the help icon
            below.
          </Typography>
        </Container>
      );
  }
}
