import { useIsMutating, useMutation } from '@tanstack/react-query';
import {
  PhoneAuthProvider,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth';
import { ConfirmationResult, VerifyChannel } from '../../types/auth';
import { checkVerification, sendVerifyMessage } from '../../services/auth';
import { auth } from '../../firebase';

interface SendSuccessResponse {
  confirm: (code: string) => Promise<boolean>;
}

interface Options {
  autoLogin?: boolean;
  onSendSuccess?: (result: SendSuccessResponse) => void;
  onSendError?: () => void;
  onCheckSuccess?: (isValid: boolean) => void;
  onCheckError?: () => void;
}

const CHECK_MUTATION_KEY = 'checkVerification';
const SEND_MUTATION_KEY = 'sendVerification';

function useVerifyPhoneNumber({
  autoLogin = true,
  onSendSuccess,
  onSendError,
  onCheckSuccess,
  onCheckError,
}: Options = {}) {
  const pendingCheckCount = useIsMutating({
    exact: false,
    mutationKey: [CHECK_MUTATION_KEY],
  });
  const pendingSendCount = useIsMutating({
    exact: false,
    mutationKey: [SEND_MUTATION_KEY],
  });

  const checkVerificationMutation = useMutation({
    mutationFn: async ({
      phoneNumber,
      code,
      confirmation,
    }: {
      phoneNumber: string;
      code: string;
      confirmation: ConfirmationResult;
    }) => {
      if (!phoneNumber || !code || !confirmation) {
        throw new Error('Invalid verification data');
      }
      if (confirmation.confirmationType === 'custom') {
        const customToken = await checkVerification(phoneNumber, code);

        if (autoLogin && customToken) {
          await signInWithCustomToken(auth, customToken);
        }

        return true;
      }
      if (confirmation.confirmationType === 'firebase') {
        const credential = PhoneAuthProvider.credential(
          confirmation.verificationId,
          code,
        );
        const response = await signInWithCredential(auth, credential);
        if (!response.user) throw new Error('Failed to sign in');

        return true;
      }
      return false;
    },
    onSuccess: onCheckSuccess,
    onSettled: onCheckError,
    mutationKey: [CHECK_MUTATION_KEY],
  });

  const sendVerificationMutation = useMutation({
    mutationFn: async ({
      phoneNumber,
      channel,
    }: {
      phoneNumber: string;
      channel: VerifyChannel;
    }) => {
      const isSent = await sendVerifyMessage(phoneNumber, channel);
      if (!isSent) {
        throw new Error('Failed to send verification message');
      }

      const confirm = async (code: string) => {
        const result = await checkVerificationMutation.mutateAsync({
          phoneNumber,
          code,
          confirmation: {
            confirmationType: 'custom',
          },
        });
        return result;
      };

      return {
        confirm,
      };
    },
    onSuccess: onSendSuccess,
    onError: onSendError,
    mutationKey: [SEND_MUTATION_KEY],
  });

  /**
   * Global pending state
   */
  const isPending =
    pendingCheckCount > 0 ||
    pendingSendCount > 0 ||
    checkVerificationMutation.isPending ||
    sendVerificationMutation.isPending;

  return {
    sendVerificationMutation,
    checkVerificationMutation,
    isPending,
  };
}

export default useVerifyPhoneNumber;
