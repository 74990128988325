import { Box, SwipeableDrawer, Theme, useMediaQuery } from '@mui/material';
import { PropsWithChildren } from 'react';
import SmallModal from '../SmallModal';

const drawerBleeding = 56;

interface Props extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalForLargeScreen?: boolean;
}

const BREAKPOINT = 'md';

function BottomDrawer({
  open,
  onClose,
  onOpen,
  children,
  modalForLargeScreen = true,
}: Props) {
  const isBreakpointOrLarger = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(BREAKPOINT),
  );

  const isSwipableDrawer = !isBreakpointOrLarger || !modalForLargeScreen;

  if (!isSwipableDrawer) {
    return (
      <SmallModal open={open} onClose={onClose} hideCloseButton>
        <Box py={4} px={2}>
          {children}
        </Box>
      </SmallModal>
    );
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        },
      }}>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding={1}>
        <Box
          sx={{
            width: 60,
            height: 6,
            backgroundColor: theme => theme.palette.lightGrey.main,
            borderRadius: 3,
          }}
        />
      </Box>
      <Box
        sx={{
          px: 2.5,
          pb: 4,
          pt: 1.5,
          minHeight: '100px',
          maxHeight: '75vh',
          overflow: 'auto',
        }}>
        {children}
      </Box>
    </SwipeableDrawer>
  );
}

export default BottomDrawer;
