import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import BottomActionBar from '../Global/BottomActionBar';
import ResendCode from './ResendCode';
import { LoginValues } from '../../types/auth';
import AuthCode from './AuthCode';
import useVerifyPhoneNumber from '../../hooks/global/useVerifyPhoneNumber';

type Props = {
  loginValues: LoginValues;
  setLoginValues: React.Dispatch<React.SetStateAction<LoginValues>>;
  prevStep: () => void;
};

function Code({ loginValues, setLoginValues, prevStep }: Props) {
  const [result, setResult] = React.useState('');

  const { isPending, checkVerificationMutation } = useVerifyPhoneNumber();

  const valid = !!result && result.length === 6;

  const handleOnChange = (res: string) => {
    setResult(res);
  };

  const header = "What's the code?";

  const authenticate = React.useCallback(async () => {
    checkVerificationMutation.mutate({
      phoneNumber: loginValues.userPhone!,
      code: result,
      confirmation: loginValues.confirmationResult!,
    });
  }, [
    checkVerificationMutation,
    loginValues.confirmationResult,
    loginValues.userPhone,
    result,
  ]);

  const errorMessage = (
    <Typography variant="h3" color="primary" className="error">
      Code could not be verified. Please try again
    </Typography>
  );

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault(); // Prevent the default form submission behavior
      authenticate(); // Call the sendCode function to handle the logic
    },
    [authenticate],
  );

  return (
    <form style={{ marginTop: 63 }} onSubmit={handleSubmit}>
      <Box className="form-header-title">
        <Typography variant="h2" className="form-header">
          {header}
        </Typography>
        <Typography variant="body2" className="form-sub-header">
          Enter the code sent to <strong>{loginValues.userPhone}</strong>
        </Typography>
      </Box>

      <Box className="input-container no-gap">
        <AuthCode label="" value={result} onChange={handleOnChange} />
        {checkVerificationMutation.isError ? errorMessage : null}
        <ResendCode
          phone={loginValues.userPhone}
          onSuccess={response => {
            setLoginValues(prev => ({
              ...prev,
              confirmationResult: response,
            }));
          }}
        />
      </Box>

      <BottomActionBar
        primaryText="Continue"
        primaryDisabled={!valid || isPending}
        primaryAction={authenticate}
        isSubmit
        secondaryAction={prevStep}
        secondaryText="Back"
      />
    </form>
  );
}

export default Code;
