/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useIdToken } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import '../App.css';
import { Appointment, Business, Employee, User } from '../interfaces';
import Login from '../components/auth/Login';
import { auth } from '../firebase';
import { getUser } from '../services/user';
import { getEmployeeById } from '../services/employee';
import { getAppointment } from '../services/appointment';
import Reschedule from '../components/Booking/Flows/Reschedule';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';
import { getBusinessById } from '../services/business';
import { getAppointmentStatus } from '../utils/appointment';

export default function ReschedulePage() {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [employee, setEmployee] = React.useState<Employee>();
  const [business, setBusiness] = React.useState<Business>();
  const [user, setUser] = React.useState<User>();
  const [loading, setLoading] = React.useState(true);
  const [userMatch, setUserMatch] = React.useState(false);
  const [authUser, authLoading] = useIdToken(auth);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [employeeError, setEmployeeError] = React.useState(false);

  const status = getAppointmentStatus(appointment);

  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        try {
          const tempUser = await getUser().catch(() => {
            setError(true);
          });

          if (tempUser.userId) {
            const createdAt = new Date(tempUser.createdAt);
            window.heap.identify(tempUser.userId);
            window._cio.identify({
              // Required attributes — you must include at least one of the following
              id: tempUser.userId, // Unique id for the user in your application.
              email: tempUser.email, // Email of the currently signed in user.

              created_at: Math.floor(createdAt.getTime() / 1000),
              first_name: tempUser.firstName,
              last_name: tempUser.lastName,
              phone_number: tempUser.phoneNumber,
            });
          }

          const tempAppointment = await getAppointment(appointmentId).catch(
            () => {
              setError(true);
            },
          );

          if (tempAppointment.customerId === tempUser.userId) {
            setUserMatch(true);
          }

          if (tempUser.role === 'ADMIN') {
            setUserMatch(true);
          }

          const tempEmployee = await getEmployeeById(
            tempAppointment.employeeId,
          ).catch(() => {
            setEmployeeError(true);
          });

          setUser({
            ...tempUser,
          });

          if (tempAppointment) {
            window._cio.page('Reschedule', {
              appointmentId: tempAppointment.appointmentId,
            });

            setAppointment({
              ...tempAppointment,
            });

            const tempBusiness = await getBusinessById(
              tempAppointment.businessId,
            ).catch(() => {
              // log error
            });

            if (tempBusiness) {
              setBusiness({
                ...tempBusiness,
              });
            }
          }

          if (tempEmployee) {
            setEmployee({
              ...tempEmployee,
            });
          }
        } catch {
          setError(true);
        }
        setDoneLoading(true);
      }

      setLoading(false);
    }

    if (loading || (authUser && !doneLoading)) {
      fetchData();
    }
  }, [
    appointmentId,
    loading,
    appointment,
    authUser,
    user,
    employee,
    doneLoading,
  ]);

  const signout = React.useCallback(() => {
    signOut(auth);
  }, []);

  switch (true) {
    // Everything is loading
    case loading || authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User authed, everything else is loading
    case authUser &&
      !(user && employee && appointment) &&
      !employeeError &&
      !error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <TopBar title="Login" subtitle="Your Appointment" />
          <Login />
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. Appointment status is undefined
    case authUser && appointment && !status:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Could not locate your booking. Try again or reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. User does not match customer ID of the booking they're trying to access
    case authUser && appointment && !userMatch:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              The phone number you logged in with does not match the user for
              this booking. Log out, and then enter the phone number you used to
              create this booking to access this page.
            </Typography>
            <Button variant="contained" className="next done" onClick={signout}>
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. Appointment is not yet confirmed
    case authUser && appointment && status !== 'CONFIRMED' && userMatch:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Reschedule" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              Your appointment can not be rescheduled since the status is not
              yet confirmed. Please visit{' '}
              <Link
                href={`/booking/${appointment!.appointmentId}`}
                id="cant-reschedule">
                this page
              </Link>{' '}
              to check the current status of your appointment.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    // Everything is loaded. Employee does not allow reschedules
    case authUser &&
      appointment &&
      status === 'CONFIRMED' &&
      userMatch &&
      employee &&
      employee.reschedulePolicy === 'CLOSED':
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Reschedule" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              {employee?.displayName} does not allow reschedules. You can chat
              with {employee?.displayName} through your booking link to request
              a reschedule:{' '}
              <Link
                href={`/booking/${appointment!.appointmentId}`}
                id="cant-reschedule">
                Booking Link
              </Link>
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );

    case authUser &&
      appointment &&
      status === 'CONFIRMED' &&
      userMatch &&
      user &&
      !employeeError:
      return employee ? (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar
            title={employee.displayName || employee.user.firstName}
            subtitle="Reschedule Appointment"
          />
          <Reschedule
            appointment={appointment!}
            employee={employee}
            business={business}
          />
          <PorterFooter />
        </Box>
      ) : (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    case error || employeeError:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above. You may be logged in with
              a different account than the one you used to create the booking.
              Try logging out, and make sure to log back in with the same phone
              number you used to create the booking.
            </Typography>
            <Button
              variant="contained"
              className="next done"
              onClick={signout}
              id="sign-out">
              Sign Out
            </Button>
          </Box>
          <PorterFooter />
        </Box>
      );

    default:
      return (
        <Box
          style={{
            height: window.innerHeight,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 600,
            margin: 'auto',
          }}>
          <TopBar title="Error" subtitle="Your appointment" />
          <Box
            style={{
              flex: '1 1 0',
              marginTop: 63,
              paddingBottom: 165,
            }}>
            <Typography variant="h1">
              There was an error loading your booking. Please reach out to our
              support team using the help icon above.
            </Typography>
          </Box>
          <PorterFooter />
        </Box>
      );
  }
}
