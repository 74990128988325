/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Typography from '@mui/material/Typography';
import { addMinutes, isSameMinute } from 'date-fns';
import { Button } from '@mui/material';
import {
  confirmAppointment,
  reschedule,
  reserveAppointment,
} from '../../../services/appointment';
import { Appointment } from '../../../interfaces';
import LoadingModal from '../../Global/LoadingModal';

interface Props {
  appointment: Appointment;
  startDateTime: Date;
  status: string;
  inAppointmentExpired: Boolean;
}

function CheckoutForm({
  appointment,
  startDateTime,
  status,
  inAppointmentExpired,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [submitError, setSubmitError] = React.useState(false);
  const [loadingElement, setLoadingElement] = React.useState(true);
  const [errorFatal, setErrorFatal] = React.useState(false);
  const [tempOnschedId, setTempOnschedId] = React.useState<string>();
  const [modalOpen, setModalOpen] = React.useState(false);

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Confirming appointment...';

  React.useEffect(() => {
    if (elements) {
      const paymentElement = elements.create('payment');
      paymentElement.on('ready', () => {
        setLoadingElement(false);
      });
      paymentElement.mount('#payment-element');
    }
  }, [elements]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setModalOpen(true);
    setSubmitting(true);
    setSubmitError(false);
    setErrorMessage('');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setSubmitError(true);
      setModalOpen(false);
      setErrorMessage(
        'Please refresh the page and try again. You have not yet been charged.',
      );
      setSubmitting(false);
      return;
    }

    // If appointment ID or duration don't exist, set and display error message. Don't continue submit function
    if (!appointment.appointmentId || !appointment.appointmentLengthInMinutes) {
      setSubmitError(true);
      setModalOpen(false);
      setErrorMessage(
        'An unexpected error occured scheduling this appointment. You have not been charged. Please refresh the page and try again.',
      );
      setSubmitting(false);
      return;
    }

    if (status === 'schedule') {
      // If onschedID does not exist, or the initial appointment has expired, reserve the appointment in Onsched (appointment is accepted and user has not clicked confirm button yet)
      if (
        (!appointment.onschedId && !tempOnschedId) ||
        inAppointmentExpired ||
        (appointment.startDateTime &&
          !isSameMinute(startDateTime, new Date(appointment.startDateTime)))
      ) {
        // Set end date time based on start time and duration
        const endDateTime = addMinutes(
          startDateTime,
          appointment.appointmentLengthInMinutes,
        );

        // Reserve appointment in Onsched
        const tempAppointment = await reserveAppointment(
          startDateTime,
          endDateTime,
          appointment.appointmentId,
        ).catch(() => {
          const tempErrorMessage =
            'This time is no longer available. Please choose a new date and time to schedule and confirm your appointment. You have not been charged.';
          setSubmitError(true);
          setModalOpen(false);
          setSubmitting(false);
          setErrorMessage(tempErrorMessage);
        });

        if (!tempAppointment) {
          return;
        }
        setTempOnschedId(tempAppointment.data.result.onschedId);
      }

      // Once onsched ID exists, process payment for this appointment
      await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
          },
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setSubmitError(true);
            setModalOpen(false);
            setSubmitting(false);
            setErrorMessage(
              result.error.message ||
                'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
            );
            return;
          }

          const paymentStatus = 'SUCCESS';

          const confirmResponse = await confirmAppointment(
            appointment.appointmentId!,
            paymentStatus,
          ).catch(async () => {
            // If first call fails, try to confirm again when the error is caught
            const newConfirmResponse = await confirmAppointment(
              appointment.appointmentId!,
              paymentStatus,
            ).catch(() => {
              window._cio.track('Schedule & Confirm', {
                appointment: appointment.appointmentId,
              });

              window.location.replace(`/booking/${appointment.appointmentId}`);
            });

            // If everything went through, refresh the booking page to pull new data
            if (newConfirmResponse) {
              window._cio.track('Schedule & Confirm', {
                appointment: appointment.appointmentId,
              });

              window.location.replace(`/booking/${appointment.appointmentId}`);
            }
          });

          // If everything went through, refresh the booking page to pull new data
          if (confirmResponse) {
            window._cio.track('Schedule & Confirm', {
              appointment: appointment.appointmentId,
            });

            window.location.replace(`/booking/${appointment.appointmentId}`);
          }
        });
    } else if (status === 'reschedule') {
      await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `https://${window.location.hostname}/booking/${appointment.appointmentId}`,
          },
          redirect: 'if_required',
        })
        .then(async result => {
          if (result.error) {
            setSubmitError(true);
            setModalOpen(false);
            setSubmitting(false);
            setErrorMessage(
              result.error.message ||
                'An unexpected error occured. You have not been charged. Please try again or reach out to our support team for assistance',
            );
            return;
          }

          // Set end date time based on start time and duration
          const endDateTime = addMinutes(
            startDateTime,
            appointment.appointmentLengthInMinutes!,
          );

          const depositPrice =
            appointment.depositPrice || appointment.reschedulePrice;
          const rescheduleResponse = await reschedule(
            startDateTime,
            endDateTime,
            appointment.appointmentId!,
            depositPrice,
          ).catch(() => {
            setSubmitError(true);
            setModalOpen(false);
            setSubmitting(false);
            setErrorFatal(true);
            setErrorMessage(
              'Your payment was processed, but an unexpected error occured while rescheduling the appointment. Please reach out to our support team to finalize rescheduling your appointment',
            );
          });

          // If everything went through, refresh the booking page to pull new data
          if (rescheduleResponse) {
            window.location.replace(`/booking/${appointment.appointmentId}`);
          }
        });
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!errorFatal ? <div id="payment-element" /> : null}
      {submitting && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={modalOpen}
        />
      )}

      {!submitting && stripe && !loadingElement && !errorFatal ? (
        <Button
          variant="contained"
          className="primary"
          id="confirm-apt-with-deposit"
          type="submit">
          Confirm &amp; Pay
        </Button>
      ) : null}

      {submitError ? (
        <Typography variant="body1" className="error">
          {errorMessage}
        </Typography>
      ) : null}
    </form>
  );
}

export default CheckoutForm;
