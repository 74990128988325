import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { ConfirmationResult } from '../../types/auth';
import ResendDrawer from './ResendDrawer';
import useStateCooldown from '../../hooks/useStateCooldown';

interface Props {
  phone: string | undefined;
  onSuccess?: (response: ConfirmationResult) => void;
  containerSx?: SxProps<Theme>;
}

function ResendCode({ phone, onSuccess, containerSx }: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [disabledResend, setDisabledResend] = useStateCooldown(15_000);

  const isDisabled = isModalOpen || disabledResend;

  const onCodeResent = (response: ConfirmationResult) => {
    onSuccess?.(response);
    setDisabledResend(true);
  };

  return (
    <>
      <Box sx={containerSx}>
        <LoadingButton
          onClick={() => {
            setIsModalOpen(true);
          }}
          disableRipple
          disabled={isDisabled}
          variant="link"
          sx={theme => ({
            my: 3,
            color: theme.palette.primary.main,
            minWidth: 0,
            mx: 'auto',
            textDecoration: 'none',
          })}>
          <Typography
            variant="h5"
            component="p"
            color={isDisabled ? 'textDisabled' : 'primary'}>
            Problems receiving the code?
          </Typography>
        </LoadingButton>
      </Box>
      {!!phone && (
        <ResendDrawer
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          onOpen={() => {
            setIsModalOpen(true);
          }}
          phoneNumber={phone}
          onSuccess={onCodeResent}
        />
      )}
    </>
  );
}

export default ResendCode;
