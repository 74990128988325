import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import BottomActionBar from '../Global/BottomActionBar';
import { FormValues } from '../../interfaces';
import FormHeader from './FormHeader';

type Props = {
  formValues: FormValues;
  setFormValues: any;
  nextStep: () => void;
  prevStep: () => void;
};

const validationSchema = yup.object({
  availability: yup
    .string()
    .required('Please enter your availability for this appointment'),
});

function GeneralAvailability({
  formValues,
  setFormValues,
  nextStep,
  prevStep,
}: Props) {
  const questionValues = {
    header:
      'Please provide days of the week and times that you are generally available',
  };

  const formik = useFormik({
    initialValues: {
      availability: formValues.availability,
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: values => {
      setFormValues({
        ...formValues,
        ...values,
      });
      sessionStorage.setItem('availability', values.availability!);
      nextStep();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="general-availability">
      <FormHeader header={questionValues.header} />
      <Box className="input-container">
        <TextField
          id="availability"
          name="availability"
          value={formik.values.availability}
          onChange={formik.handleChange}
          error={
            formik.touched.availability && Boolean(formik.errors.availability)
          }
          helperText={formik.touched.availability && formik.errors.availability}
          multiline
          placeholder="Enter your general availability or at least 3 specific dates and times that you're free"
          minRows={3}
          className="textarea"
        />
      </Box>
      <BottomActionBar
        primaryText="Continue"
        isSubmit
        primaryDisabled={!formik.isValid}
        secondaryText="Back"
        secondaryAction={prevStep}
      />
    </form>
  );
}

export default GeneralAvailability;
