import * as React from 'react';
import { useFormikContext } from 'formik';
import { Box, IconButton, TextField, useTheme } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { ServiceFormValues } from '../../../interfaces';
import PageLayout from '../../Global/PageLayout';

type Props = {
  nextStep: () => void;
  prevStep?: () => void;
};

function Guests({ nextStep, prevStep }: Props) {
  const theme = useTheme();

  const formik = useFormikContext<ServiceFormValues>();
  const numberOfGuests = formik.values.numberOfGuests || 1;

  const questionValues = {
    header: `How many are in your party?`,
    subHeader:
      'If you’re scheduling for more than one person to be pierced, add them here',
  };

  const handleIncrement = () => {
    if (numberOfGuests < 5) {
      formik.setFieldValue('numberOfGuests', numberOfGuests + 1);
    }
  };

  const handleDecrement = () => {
    if (numberOfGuests > 1) {
      formik.setFieldValue('numberOfGuests', numberOfGuests - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (!Number(value) || value < 1) {
      value = 1;
    } else if (value > 5) {
      value = 5;
    }
    formik.setFieldValue('numberOfGuests', value);
  };

  const iconButtonStyles = {
    backgroundColor: theme.palette.lightGrey.main,
    borderColor: theme.palette.darkGrey.main,
    borderWidth: 1,
    borderStyle: 'solid',
    ':disabled': {
      backgroundColor: theme.palette.lightGrey.main,
    },
  };

  return (
    <PageLayout
      header={questionValues.header}
      subheader={questionValues.subHeader}
      onNext={nextStep}
      onBack={prevStep}>
      <Box
        display="flex"
        sx={{ gap: 2 }}
        alignItems="center"
        justifyContent="center">
        <IconButton
          onClick={handleDecrement}
          disabled={numberOfGuests <= 1}
          sx={iconButtonStyles}
          aria-label="Decrease number of guests">
          <RemoveIcon htmlColor={theme.palette.darkGrey.main} />
        </IconButton>
        <TextField
          type="text"
          inputProps={{
            min: 1,
            max: 5,
            style: { textAlign: 'center', width: '60px' },
            'aria-label': 'Number of guests',
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
          disabled
          value={numberOfGuests}
          onChange={handleChange}
          error={Boolean(
            formik.touched.numberOfGuests && formik.errors.numberOfGuests,
          )}
          helperText={
            formik.touched.numberOfGuests && formik.errors.numberOfGuests
          }
        />
        <IconButton
          onClick={handleIncrement}
          disabled={numberOfGuests >= 5}
          sx={iconButtonStyles}
          aria-label="Increase number of guests">
          <AddIcon htmlColor={theme.palette.darkGrey.main} />
        </IconButton>
      </Box>
    </PageLayout>
  );
}

export default Guests;
