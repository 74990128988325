/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import Talk from 'talkjs';
import { format } from 'date-fns';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useParams } from 'react-router-dom';
import { useIdToken } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import '../App.css';
import { Roles } from '@prisma/client';
import { Appointment, Business, GeneralRequest, User } from '../interfaces';
import Login from '../components/auth/Login';
import { auth } from '../firebase';
import { getUser } from '../services/user';
import { getAppointment } from '../services/appointment';
import './chat.css';
import TALK_APP_ID from '../talkconfig';
import { getBusinessById } from '../services/business';
import { isUserEmployeeOrAdmin } from '../utils/employee';
import { getAppointmentStatus } from '../utils/appointment';

export default function ChatPage() {
  const { appointmentId } = useParams();

  const [user, setUser] = React.useState<User>();
  const [authUser, authLoading] = useIdToken(auth);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [talkLoaded, setTalkLoaded] = React.useState(false);

  const inboxEl = React.useRef<HTMLDivElement>(null);
  const [conversationSelected, setConversationSelected] = React.useState(false);
  const [inbox, setInbox] = React.useState<Talk.Inbox>();
  const [session, setSession] = React.useState<Talk.Session>();

  const [business, setBusiness] = React.useState<Business>();
  const [appointment, setAppointment] = React.useState<Appointment>();
  const [chatHeaderAppointment, setChatHeaderAppointment] =
    React.useState<Appointment>();
  const [headerText, setHeaderText] = React.useState('');
  const [statusText, setStatusText] = React.useState('');
  const [buttonText, setButtonText] = React.useState('');

  React.useEffect(() => {
    Talk.ready.then(() => setTalkLoaded(true));
  }, []);

  // Load the user and if an appointment ID is provided, the appointment details
  React.useEffect(() => {
    async function fetchData() {
      if (authUser) {
        try {
          const tempUser = await getUser().catch(() => {
            setError(true);
          });

          if (!user) {
            setUser({
              ...tempUser,
            });
          }

          setLoading(false);

          if (appointmentId) {
            const tempAppointment = await getAppointment(appointmentId).catch(
              () => {
                setError(true);
              },
            );
            const status = getAppointmentStatus(tempAppointment);

            if (
              tempAppointment.customerId === tempUser!.userId &&
              (status === 'ACCEPTED' ||
                status === 'CONFIRMED' ||
                status === 'SCHEDULED') &&
              !appointment
            ) {
              setAppointment(tempAppointment);
              setChatHeaderAppointment(tempAppointment);
            }

            if (tempAppointment.businessId) {
              const tempBusiness = await getBusinessById(
                tempAppointment.businessId,
              ).catch(() => undefined);
              setBusiness(tempBusiness);
            }
          }
        } catch {
          setError(true);
        }
      }
    }

    if (loading) {
      fetchData();
    }
  }, [appointment, appointmentId, authUser, loading, user]);

  // Once the user and TalkJS are loaded & ready - initialize talkJS session with the current user and create and mount the inbox
  React.useEffect(() => {
    if (talkLoaded && !loading && user) {
      const userName = `${user.firstName} ${user.lastName}`;

      const talkJsId =
        user && isUserEmployeeOrAdmin(user?.role as Roles)
          ? `${user.userId}-customer`
          : user.userId;

      const currentUser = new Talk.User({
        id: talkJsId,
        name: userName,
        email: user!.email,
        phone: user!.phoneNumber,
        role: 'customer',
      });

      const tempSession = new Talk.Session({
        appId: TALK_APP_ID,
        me: currentUser,
      });

      const tempInbox = tempSession.createInbox({
        messageField: { autofocus: false },
        showChatHeader: false,
        showMobileBackButton: false,
        showFeedHeader: false,
      });

      setInbox(tempInbox);
      setSession(tempSession);
    }
  }, [loading, talkLoaded, user]);

  // Once the inbox is ready, if appointment ID is provided, select the correct conversation
  React.useEffect(() => {
    if (appointmentId && inbox && session) {
      const conversation = session.getOrCreateConversation(appointmentId);
      inbox.select(conversation);
      setConversationSelected(true);
    }
  }, [appointmentId, inbox, session]);

  const signout = React.useCallback(() => {
    signOut(auth);
  }, []);

  const backToInbox = React.useCallback(() => {
    if (
      appointmentId &&
      chatHeaderAppointment?.appointmentId === appointmentId
    ) {
      window.location.href = `/booking/${appointmentId}`;
    } else if (inbox) {
      inbox?.select(null);
    }
  }, [appointmentId, chatHeaderAppointment?.appointmentId, inbox]);

  React.useEffect(() => {
    if (chatHeaderAppointment) {
      const employeeName =
        chatHeaderAppointment.employee?.displayName ||
        chatHeaderAppointment.employee?.firstName;
      const status = getAppointmentStatus(chatHeaderAppointment);
      if (status === 'REQUESTED') {
        const requestedOn = new Date(chatHeaderAppointment.createdAt || '');
        const requestedText = format(requestedOn, 'MM/dd/yyyy');
        setHeaderText(`Request with ${employeeName}`);
        setStatusText(`Requested on ${requestedText}`);
        setButtonText('View request');
      } else if (status === 'ACCEPTED') {
        const acceptedOn = new Date(chatHeaderAppointment.updatedAt || '');
        const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
        setHeaderText(`Request with ${employeeName}`);
        setStatusText(`Accepted on ${acceptedText}`);
        setButtonText('Schedule & Confirm');
      } else if (status === 'REJECTED') {
        const acceptedOn = new Date(chatHeaderAppointment.updatedAt || '');
        const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
        setHeaderText(`Request with ${employeeName}`);
        setStatusText(`Rejected on ${acceptedText}`);
        setButtonText('View request');
      } else if (status === 'CONFIRMED') {
        const scheduledOn = new Date(chatHeaderAppointment.startDateTime || '');
        const scheduledText = format(scheduledOn, 'MM/dd/yyyy');
        setHeaderText(`Appointment with ${employeeName}`);
        setStatusText(`Scheduled on ${scheduledText}`);
        setButtonText('View appointment');
      } else if (status === 'SCHEDULED') {
        const scheduledOn = new Date(chatHeaderAppointment.startDateTime || '');
        const scheduledText = format(scheduledOn, 'MM/dd/yyyy');
        setHeaderText(`Appointment with ${employeeName}`);
        setStatusText(`Scheduled on ${scheduledText}`);
        setButtonText('Confirm appointment');
      } else if (status === 'CANCELED') {
        const acceptedOn = new Date(chatHeaderAppointment.updatedAt || '');
        const acceptedText = format(acceptedOn, 'MM/dd/yyyy');
        setHeaderText(`Request with ${employeeName}`);
        setStatusText(`Canceled on ${acceptedText}`);
        setButtonText('View appointment');
      } else if ((chatHeaderAppointment as GeneralRequest).requestId) {
        const requestedOn = new Date(chatHeaderAppointment.createdAt || '');
        const requestedText = format(requestedOn, 'MM/dd/yyyy');
        setHeaderText(`Request with ${business?.name}`);
        setStatusText(`Requested on ${requestedText}`);
        setButtonText('View request');
      }
    }
  }, [chatHeaderAppointment, business]);

  React.useEffect(() => {
    if (inbox) {
      inbox.mount(inboxEl.current);

      inbox.on('conversationSelected', async ({ conversation, others }) => {
        if (others) {
          setConversationSelected(true);
        } else {
          setConversationSelected(false);
        }

        if (conversation) {
          const tempAppointment = await getAppointment(conversation.id).catch(
            () => {},
          );

          if (tempAppointment.businessId) {
            const tempBusiness = await getBusinessById(
              tempAppointment.businessId,
            ).catch(() => undefined);
            setBusiness(tempBusiness);
          }

          if (tempAppointment) {
            setChatHeaderAppointment(tempAppointment);
          } else {
            setChatHeaderAppointment(undefined);
          }
        }
      });
    }
  }, [inbox]);

  switch (true) {
    // Everything is loading
    case authLoading:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="body1">Loading...</Typography>
        </Container>
      );

    // User is unauthenticated
    case !authLoading && !authUser:
      return (
        <Container maxWidth="lg" className="login">
          <Login />
        </Container>
      );

    case error:
      return (
        <Container maxWidth="lg" className="data-error">
          <Typography variant="h1">
            Error loading user details. Try refreshing the page or signing out
            and signing back in.
          </Typography>
          <Button
            variant="contained"
            className="next done"
            onClick={signout}
            id="sign-out">
            Sign Out
          </Button>
        </Container>
      );

    default:
      return (
        <Container maxWidth="lg" className="chat-container">
          {!conversationSelected && (
            <div className="chat-header">
              <Typography variant="h1">Inbox</Typography>
            </div>
          )}
          <div
            id="inbox-container"
            style={{
              display: 'flex',
              height: '90vh',
              flexDirection: 'column',
            }}>
            {conversationSelected && (
              <div className="message-header">
                {chatHeaderAppointment && (
                  <>
                    <div className="chat-nav">
                      <Button onClick={backToInbox} className="chat-back">
                        <ChevronLeftIcon />
                        {chatHeaderAppointment.employee ? (
                          <Typography variant="h2">
                            {chatHeaderAppointment.employee?.displayName ||
                              chatHeaderAppointment.employee?.firstName}
                          </Typography>
                        ) : (
                          <Typography variant="h2">{business?.name}</Typography>
                        )}
                      </Button>
                    </div>
                    <div className="chat-appointment-box">
                      {chatHeaderAppointment.refImgsId &&
                      chatHeaderAppointment.refImgsId.length > 0 ? (
                        <Avatar
                          alt="appointment image"
                          src={chatHeaderAppointment.refImgsId[0]}
                        />
                      ) : (
                        <Avatar
                          alt="appointment image"
                          src="https://uploads-ssl.webflow.com/62b4bc5a9e85d037dbfff77c/62b4bf47f7e8c4164e419a4c_maxim-hopman-52Kf36w124Y-unsplash%20(1).jpg"
                        />
                      )}
                      <div className="chat-appointment-text">
                        <Typography variant="h3">{headerText}</Typography>
                        <Typography variant="body2">{statusText}</Typography>
                        {!chatHeaderAppointment.checkoutSessionCompleted && (
                          <Button
                            variant="outlined"
                            style={{
                              marginTop: 10,
                              fontWeight: 400,
                              marginLeft: 0,
                              float: 'left',
                            }}
                            href={`/booking/${
                              chatHeaderAppointment.appointmentId ||
                              (chatHeaderAppointment as GeneralRequest)
                                .requestId
                            }`}>
                            {buttonText}
                          </Button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              className="chat-div"
              style={{
                flex: '1 1 auto',
                maxHeight: 700,
              }}
              ref={inboxEl}
            />
          </div>
        </Container>
      );
  }
}
