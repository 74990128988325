import { WaiverRequestStatus } from '@prisma/client';
import * as React from 'react';
import { useQueries } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { Appointment } from '../../interfaces';
import { getAppointmentWaiver, trackWaiverView } from '../../services/waivers';

function useWaiver({
  appointment,
  isPreview,
}: {
  appointment: Appointment | undefined;
  isPreview?: boolean;
}) {
  const [params] = useSearchParams();
  const previewWaiverId = isPreview ? params.get('waiver') : undefined;

  const waiverId = isPreview
    ? previewWaiverId
    : appointment?.waiverRequest?.waiverId;

  const needsWaiver = React.useMemo(
    () =>
      (isPreview && !!previewWaiverId) ||
      (!!waiverId &&
        (appointment?.waiverRequest?.status === WaiverRequestStatus.QUEUED ||
          appointment?.waiverRequest?.status === WaiverRequestStatus.SENT) &&
        isSameDay(new Date(), new Date(appointment!.startDateTime || ''))),
    [isPreview, previewWaiverId, waiverId, appointment],
  );

  const [{ data: waiver, isLoading, isFetching, isError, error }] = useQueries({
    queries: [
      {
        queryKey: ['waiver', waiverId, needsWaiver, isPreview],
        queryFn: () => getAppointmentWaiver(waiverId!, isPreview),
        enabled: !!needsWaiver && !!waiverId,
      },
      {
        queryKey: ['waiver-view', appointment?.waiverRequest?.id],
        queryFn: () =>
          trackWaiverView({ waiverRequestId: appointment?.waiverRequest?.id! }),
        enabled:
          !!needsWaiver &&
          !!appointment?.businessId &&
          !!appointment?.waiverRequest?.id,
      },
    ],
  });

  return {
    previewWaiverId,
    needsWaiver,
    waiver,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useWaiver;
