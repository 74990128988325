import { useQuery } from '@tanstack/react-query';
import { getPiercingLocations } from '../../services/choices';

function useGetPiercingLocations(piercingLocationCategory?: string) {
  const {
    data: locations,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery({
    queryKey: ['piercing-locations'],
    queryFn: async () => {
      const tempLocations = await getPiercingLocations();
      return tempLocations;
    },
  });

  const categories = Array.from(
    new Set(locations?.map(item => item.locationCategory)),
  );

  const types = locations?.filter(
    location => location.locationCategory === piercingLocationCategory,
  );

  return {
    locations,
    categories,
    types,
    isLoading,
    isFetching,
    isError,
    error,
  };
}

export default useGetPiercingLocations;
