import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import heic2any from 'heic2any'; // Import the heic2any library
import { storage } from '../../firebase';
import { Image as ImageType } from '../../interfaces';
import LoadingModal from '../Global/LoadingModal';

const MAX_IMAGES = 6;

type Props = {
  images: ImageType[];
  setImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  singleImage?: boolean;
  localImage?: boolean;
  compress?: boolean;
  accept?: string;
};

function ImageUpload({
  images,
  setImages,
  setIsValid,
  singleImage,
  localImage,
  compress = true,
  accept,
}: Props) {
  const theme = useTheme();
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const modalMessage =
    'This could take a minute. Please do not refresh the page.';
  const modalHeader = 'Uploading images...';

  // Function to convert HEIC images to JPEG
  async function convertHEICtoJPEG(file: File): Promise<Blob | File> {
    if (file.type === 'image/heic') {
      // Convert HEIC to JPEG
      const jpegBlobs = await heic2any({ blob: file, toType: 'image/jpeg' });

      // If multiple Blobs are returned, merge them into a single Blob
      if (Array.isArray(jpegBlobs) && jpegBlobs.length > 0) {
        const mergedBlob = new Blob(jpegBlobs, { type: 'image/jpeg' });
        return mergedBlob;
      }

      // If a single Blob is returned, use it directly
      if (jpegBlobs instanceof Blob) {
        return jpegBlobs;
      }

      // Handle unexpected result from heic2any
      throw new Error('Unexpected result from heic2any');
    }

    // For other image types, return the original file without conversion
    return file;
  }

  // Function to compress JPEG, PNG, and WebP images
  async function compressImage(file: File | Blob): Promise<Blob> {
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp'
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event: ProgressEvent<FileReader>) => {
          const img = new Image();
          img.src = event.target!.result as string; // Type assertion here

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas dimensions to the desired image size
            const maxWidth = 1600; // Adjust this as needed
            const maxHeight = 1200; // Adjust this as needed

            let { width } = img;
            let { height } = img;

            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;

            // Draw the image on the canvas with the new dimensions
            ctx?.drawImage(img, 0, 0, width, height);

            // Convert the canvas content back to a compressed image
            canvas.toBlob(
              blob => {
                if (blob) {
                  resolve(blob);
                } else {
                  reject(new Error('Failed to compress image'));
                }
              },
              file.type, // Set the image type
              0.95, // Set the image quality (0.9 means 90% quality)
            );
          };
        };

        reader.onerror = reader_error => {
          reject(reader_error);
        };
      });
    }

    // For other image types, return the original file without compression
    return file;
  }

  const generateRandomString = () => {
    const randomPart = Math.random().toString(36).substring(2, 15); // Generate a random string
    const timestamp = Date.now().toString(); // Get current timestamp
    return `${timestamp}-${randomPart}`; // Combine them
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    const filePromises: Promise<ImageType>[] = [];

    if (files) {
      if (files.length > MAX_IMAGES) {
        setError('Maximum of 6 images can be uploaded');
        return;
      }

      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];

        // Validate file type and size here if needed

        // Compress and upload the image
        filePromises.push(
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve, reject) => {
            try {
              const jpegBlob = await convertHEICtoJPEG(file); // Convert HEIC to JPEG if necessary
              const finalBlob = compress
                ? await compressImage(jpegBlob)
                : jpegBlob; // Compress the image
              const fileName = generateRandomString();
              const imageRef = ref(storage, `tempImages/${fileName}`);
              if (!localImage) {
                const uploadTask = uploadBytesResumable(imageRef, finalBlob);

                uploadTask.on(
                  'state_changed',
                  () => {},
                  uploadError => reject(uploadError),
                  async () => {
                    const downloadURL = await getDownloadURL(imageRef);
                    resolve({ name: fileName, url: downloadURL }); // Use generated file name
                  },
                );
              } else {
                resolve({
                  name: fileName,
                  url: URL.createObjectURL(finalBlob),
                });
              }
            } catch (compressionError) {
              reject(compressionError);
            }
          }),
        );
      }
    }

    try {
      setModalOpen(true);
      setLoading(true);
      const newImages = await Promise.all(filePromises);
      setImages(prevImages => [...prevImages, ...newImages]);
      setIsValid?.(true);
      setError('');
    } catch (imageError) {
      setError('Failed to upload one or more images');
      setModalOpen(false);
    } finally {
      setLoading(false);
      setModalOpen(false);
    }
  };

  const handleRemoveImage = (image: ImageType) => {
    setImages(prevImages => prevImages.filter(i => i !== image));
  };

  const renderImagePreview = (image: ImageType) => (
    <Grid item xs={6} md={4} lg={3} key={image.name}>
      <div className="image-upload-preview">
        <img src={image.url} alt={image.name} />
        <button
          className="remove-image"
          onClick={() => handleRemoveImage(image)}
          type="button">
          <DeleteIcon
            fontSize="medium"
            htmlColor={theme.palette.darkGrey.main}
          />
        </button>
      </div>
    </Grid>
  );

  return (
    <div className="imageUpload">
      <InputLabel className="image-label">
        <AddIcon
          htmlColor={theme.palette.darkGrey.main}
          fontSize="medium"
          sx={{ marginBottom: 0 }}
        />
        <Typography variant="body1" style={{ lineHeight: '22px' }}>
          Add Photos
        </Typography>
        <input
          className="image-input"
          type="file"
          name="image-upload"
          onChange={handleImageChange}
          multiple={!singleImage}
          hidden
          accept={accept || 'image/*, .HEIC, .heic'}
        />
      </InputLabel>

      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {images.map(image => renderImagePreview(image))}
      </Grid>

      {error && (
        <Typography variant="body1" className="error">
          {error}
        </Typography>
      )}
      {loading && (
        <LoadingModal
          header={modalHeader}
          message={modalMessage}
          open={modalOpen}
        />
      )}
    </div>
  );
}

export default ImageUpload;
