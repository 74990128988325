import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { ValidationError } from 'yup';
import { toast } from 'react-toastify';
import PageLayout from '../../Global/PageLayout';
import SignaturePad from './SignaturePad';
import { WaiverSectionProps } from '../../../types/waivers';
import { SIGNATURE_ATTESTATION } from '../../../constants/waivers';

export default function CompleteAndSign({
  onBack,
  onSubmit,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const sigCanvas = React.useRef<SignatureCanvas | null>(null);

  const [isTouched, setIsTouched] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const createSignature = () => {
    if (!sigCanvas.current) throw new Error('Signature canvas not found');
    const isEmpty = sigCanvas.current.isEmpty();
    if (isEmpty) throw new Error('Signature is required');
    const url = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    return url;
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const signatureUrl = createSignature();
      waiverForm.set(prev => ({
        ...prev,
        signature: signatureUrl,
      }));

      await onSubmit(signatureUrl);
    } catch (error) {
      toast.error(
        error instanceof ValidationError
          ? 'Please fill out all required fields'
          : 'An error occurred. Please try again.',
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const onBegin = () => {
    setIsTouched(true);
  };

  const onClear = () => {
    setIsTouched(false);
  };

  return (
    <PageLayout
      alert={alert}
      header="Signature"
      subheader={SIGNATURE_ATTESTATION}
      onNext={handleSubmit}
      onBack={onBack}
      primaryText="Submit"
      nextDisabled={!isTouched}
      isLoadingNext={isSubmitting}>
      <SignaturePad sigCanvas={sigCanvas} onBegin={onBegin} onClear={onClear} />
    </PageLayout>
  );
}
