import { useRef } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useCreateUnsubscribeFormEmbed from '../hooks/marketing/useCreateUnsubscribeFormEmbed';
import UnsubscribeEmbed from '../components/Global/UnsubscribeEmbed';
import TopBar from '../components/Global/TopBar';
import PorterFooter from '../components/Global/PorterFooter';

export default function UnsubscribePage() {
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customer_id');

  const { embed: unsubscribeEmbed, isLoading } =
    useCreateUnsubscribeFormEmbed(customerId);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  return (
    <Box
      style={{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600,
        margin: 'auto',
      }}>
      <TopBar title="Unsubscribe" subtitle="Unsubscribe from Marketing" />
      {isLoading && (
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {unsubscribeEmbed && !isLoading && (
        <UnsubscribeEmbed
          unsubscribeEmbed={unsubscribeEmbed}
          iframeRef={iframeRef}
        />
      )}
      <PorterFooter />
    </Box>
  );
}
