import * as React from 'react';
import PageLayout from '../../Global/PageLayout';
import { Image } from '../../../interfaces';
import ImageUpload from '../../Form/ImageUpload';
import { WaiverSectionProps } from '../../../types/waivers';

export default function IDUpload({
  waiver,
  onNext,
  onBack,
  waiverForm,
  alert,
}: WaiverSectionProps) {
  const images = React.useMemo<Image[]>(
    () =>
      waiverForm.value.identification
        ? [
            {
              name: 'ID',
              url: waiverForm.value.identification,
            },
          ]
        : [],
    [waiverForm.value.identification],
  );

  return (
    <PageLayout
      alert={alert}
      header="Photo ID Upload"
      subheader={waiver?.identificationSettings?.text}
      onNext={() => {
        onNext();
      }}
      nextDisabled={!waiverForm.value.identification}
      onBack={onBack}>
      <ImageUpload
        compress={false}
        // PDF only accepts JPEG, PNG
        accept="image/png, image/jpg, image/jpeg, .HEIC, .heic"
        localImage
        images={images}
        // dispatch set state type
        setImages={(newImages: Image[] | ((prevState: Image[]) => Image[])) => {
          if (typeof newImages === 'function') {
            waiverForm.set(prev => ({
              ...prev,
              identification: newImages([])?.[0]?.url ?? '',
            }));
          } else {
            waiverForm.set(prev => ({
              ...prev,
              value: {
                identification: newImages[0]?.url ?? '',
              },
            }));
          }
        }}
        singleImage
      />
    </PageLayout>
  );
}
