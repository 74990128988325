import * as React from 'react';
import Phone from './Phone';
import Code from './Code';
import '../Form/Form.css';
import { LoginValues } from '../../types/auth';

const defaultValues: LoginValues = {
  step: 1,
  userPhone: '',
  code: '',
  loggedIn: false,
};

export default function Login() {
  const [loginValues, setLoginValues] =
    React.useState<LoginValues>(defaultValues);

  const prevStep = React.useCallback(() => {
    const currStep = loginValues.step;
    const newStep = currStep - 1;
    setLoginValues(prev => ({
      ...prev,
      step: newStep,
    }));
  }, [loginValues]);

  switch (loginValues.step) {
    case 1:
      return (
        <Phone loginValues={loginValues} setLoginValues={setLoginValues} />
      );
    case 2:
      return (
        <Code
          loginValues={loginValues}
          setLoginValues={setLoginValues}
          prevStep={prevStep}
        />
      );
    default:
      return <div>Error</div>;
  }
}
