import * as React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Button, useTheme } from '@mui/material';

interface SectionProps {
  sigCanvas: React.RefObject<SignatureCanvas>;
  onBegin?: () => void;
  onClear?: () => void;
}

const BOTTOM_HEIGHT = '50px';

export default function SignaturePad({
  sigCanvas,
  onBegin,
  onClear,
}: SectionProps) {
  const theme = useTheme();

  const [width, setWidth] = React.useState(0);
  const [openSignature, setOpenSignature] = React.useState(false);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const onResize = () => {
      if (!containerRef.current) return;
      setWidth(containerRef.current!.clientWidth);
    };
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [containerRef]);

  const clearCanvas = () => {
    if (!openSignature) return;
    if (sigCanvas.current) {
      setOpenSignature(false);
      sigCanvas.current.clear();
      onClear?.();
    }
  };

  const onBeginDraw = () => {
    if (openSignature) return;
    onBegin?.();
    setOpenSignature(true);
  };

  return (
    <Box
      ref={containerRef}
      position="relative"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      border={`1px solid ${theme.palette.outlineGrey.main}`}
      borderRadius="5px"
      marginBottom={!openSignature ? BOTTOM_HEIGHT : undefined}>
      {!openSignature && (
        <Box
          position="absolute"
          sx={{
            pointerEvents: 'none',
          }}
          color={theme.palette.outlineGrey.main}>
          <span>Draw your signature here</span>
        </Box>
      )}
      <Box width="100%">
        <SignatureCanvas
          onBegin={onBeginDraw}
          penColor="black"
          canvasProps={{ height: '200px', width }}
          ref={sigCanvas}
        />
        {openSignature && (
          <Box
            height={BOTTOM_HEIGHT}
            boxSizing="border-box"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            borderTop={`1px solid ${theme.palette.outlineGrey.main}`}>
            <Button
              disabled={!openSignature}
              variant="text"
              type="button"
              onClick={clearCanvas}
              sx={{
                border: 'none',
                marginLeft: 'auto',
                color: 'rgb(78, 75, 75)',
                padding: 0,
                display: 'block',
              }}>
              Clear
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
