import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { ServiceFormValues } from '../../../interfaces';

function StoreServiceForm({
  storageIdentifier,
}: {
  storageIdentifier: string;
}) {
  const { values } = useFormikContext<ServiceFormValues>();

  useEffect(() => {
    if (!values) return;
    sessionStorage.setItem(storageIdentifier, JSON.stringify(values));
  }, [values, storageIdentifier]);

  return null;
}

export default StoreServiceForm;
