import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://firebasestorage.googleapis.com/v0/b/bamboo-artwork-372213.appspot.com/o/public%2Fnew.pdf.worker.min.mjs?alt=media&token=3762b649-92e5-4997-8a70-66f34e7abc88';

export const readFileData = (file: File | Blob) =>
  new Promise<
    undefined | null | string | ArrayBuffer | PromiseLike<string | ArrayBuffer>
  >((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target?.result);
    };
    reader.onerror = err => {
      reject(err);
    };
    reader.readAsDataURL(file);
  });

export const convertPdfToImages = async (file: File | Blob) => {
  const images: string[] = [];
  const data = await readFileData(file);
  if (!data) throw new Error('Failed to read file data');
  const pdf = await pdfjs.getDocument(data).promise;
  const canvas = document.createElement('canvas');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < pdf.numPages; i++) {
    // eslint-disable-next-line no-await-in-loop
    const page = await pdf.getPage(i + 1);
    const viewport = page.getViewport({ scale: 2 }); // adjust scale as needed - smaller scale = more compressed images
    const context = canvas.getContext('2d');
    // eslint-disable-next-line no-continue
    if (!context) continue;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    // eslint-disable-next-line no-await-in-loop
    await page.render({ canvasContext: context, viewport }).promise;
    images.push(canvas.toDataURL());
  }
  canvas.remove();
  return images;
};
