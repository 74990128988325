import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { limitFit } from '@cloudinary/url-gen/actions/resize';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Image } from '../../interfaces';

interface GalleryImage {
  original: string;
  thumbnail: string;
}

type Props = {
  images: Image[] | undefined;
  closeGallery: () => void;
  currentIndex: number;
};

function MyImageGallery({ images, closeGallery, currentIndex }: Props) {
  // textInput must be declared here so the ref can refer to it
  const [galleryImages, setGalleryImages] = React.useState<GalleryImage[]>();
  const [imageCount, setImageCount] = React.useState<number>();
  const [currentImageIndex, setCurrentImageIndex] =
    React.useState<number>(currentIndex);
  const desktop = useMediaQuery('(min-width:744px)');

  React.useEffect(() => {
    async function loadImages() {
      if (images && images.length > 0) {
        setImageCount(images.length);
        if (!desktop) {
          const tempGalleryImages = await Promise.all(
            images.map(image => {
              const cldImage = new CloudinaryImage(image.imageRef, {
                cloudName: 'dsh0tsol7',
              }).resize(limitFit().width(1080));

              return {
                original: cldImage.toURL(),
                thumbnail: cldImage.toURL(),
              };
            }),
          );

          setGalleryImages(tempGalleryImages);
        } else {
          const tempGalleryImages = await Promise.all(
            images.map(image => {
              const cldImage = new CloudinaryImage(image.imageRef, {
                cloudName: 'dsh0tsol7',
              }).resize(limitFit().width(1200).height(800));

              return {
                original: cldImage.toURL(),
                thumbnail: cldImage.toURL(),
              };
            }),
          );

          setGalleryImages(tempGalleryImages);
        }
      }
    }
    loadImages();
  }, [desktop, images]);

  return (
    <div>
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: 'black',
          top: 60,
          left: 16,
          borderRadius: '50%',
          opacity: '75%',
          height: 30,
          width: 30,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 5,
        }}
        onClick={closeGallery}>
        <ChevronLeftIcon sx={{ color: 'white' }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          backgroundColor: 'black',
          top: 60,
          right: 16,
          padding: 1,
          borderRadius: '5px',
          opacity: '75%',
          zIndex: 5,
        }}>
        <Typography variant="h4" color="white">
          {currentImageIndex} / {imageCount}
        </Typography>
      </Box>
      {galleryImages && (
        <ImageGallery
          items={galleryImages}
          showNav={false}
          showPlayButton={false}
          showFullscreenButton={false}
          onSlide={(index: number) => setCurrentImageIndex(index + 1)}
          startIndex={currentIndex - 1}
        />
      )}
    </div>
  );
}

export default MyImageGallery;
