import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@mui/material';
import SelectPiercingLocation from './PiercingLocation';
import Guests from './Guests';
import Calendar from '../../Booking/Scheduler/Calendar';
import BottomActionBar from '../../Global/BottomActionBar';
import PersonalInfo from './PersonalInfo';
import ConfirmPiercing from './ConfirmPiercing';
import { ServiceFormValues } from '../../../interfaces';
import useGetBusiness from '../../../hooks/businesses/useGetBusiness';
import {
  getBaseStorageIdentifier,
  getServiceDuration,
  getServicePrice,
  getStepStorageIdentifier,
  getSteps,
} from '../../../utils/services';
import ChooseProvider from './ChooseProvider';
import useGetEmployee from '../../../hooks/employees/useGetEmployee';
import useGetServiceFromParam from '../../../hooks/services/useGetServiceFromParam';
import { BookingSteps } from '../../../constants/services';
import PageError from '../../Global/Error';

interface Props {
  step: number;
  setStep: React.Dispatch<SetStateAction<number>>;
}

export default function ServiceStep({ step, setStep }: Props) {
  const formik = useFormikContext<ServiceFormValues>();
  const { values } = formik;

  const { business } = useGetBusiness();
  const service = useGetServiceFromParam(business);
  const { employee } = useGetEmployee({ employeeId: formik.values.employeeId });

  const [clickedEdit, setClickedEdit] = useState(false);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedDateTime, setSelectedDateTime] = useState(
    values.startDateTime ?? new Date(),
  );
  const [dayIsSelected, setDayIsSelected] = useState(false);

  const steps = getSteps(service);
  const canGoBack = step > 0;

  const storageIdentifier = getBaseStorageIdentifier(service);
  const stepStorageIdentifier = getStepStorageIdentifier(storageIdentifier);

  const duration = useMemo(
    () => getServiceDuration(service, values.numberOfGuests),
    [values.numberOfGuests, service],
  );

  const price = useMemo(
    () => getServicePrice(service, values.numberOfGuests),
    [values.numberOfGuests, service],
  );

  useEffect(() => {
    if (selectedDateTime && selectedDateTime !== formik.values.startDateTime) {
      formik.setFieldValue('startDateTime', selectedDateTime);
    }
  }, [selectedDateTime, formik]);

  const backToStep = (stepName: BookingSteps) => {
    const index = steps.indexOf(stepName);
    setStep(index);
    setClickedEdit(true);
  };

  const nextStep = () => {
    if (clickedEdit) {
      backToStep(BookingSteps.CONFIRM);
    } else {
      let newStep = step + 1;
      if (steps[newStep] === 'PROVIDER' && employee) {
        newStep++;
      }

      setStep(newStep);
      sessionStorage.setItem(stepStorageIdentifier, JSON.stringify(newStep));
    }
  };

  const prevStep = () => {
    let newStep = step - 1;
    if (steps[newStep] === 'PROVIDER' && employee) {
      newStep--;
    }

    setStep(Math.max(newStep, 0));
    sessionStorage.setItem(stepStorageIdentifier, JSON.stringify(newStep));
  };

  const goBack = canGoBack ? prevStep : undefined;

  if (!service || !business) {
    return (
      <PageError
        message="There was an error loading this artist's page. Please double
          check that you've entered the url in correctly, and if the error
          persists, reach out to our support team using the help icon below."
      />
    );
  }

  switch (steps[step]) {
    case 'PIERCING_LOCATION':
      return <SelectPiercingLocation nextStep={nextStep} prevStep={goBack} />;
    case 'PROVIDER':
      return (
        <ChooseProvider
          nextStep={nextStep}
          prevStep={goBack}
          service={service}
          business={business}
        />
      );
    case 'GUESTS':
      return <Guests nextStep={nextStep} prevStep={goBack} />;
    case 'CALENDAR':
      return (
        <Box>
          {employee && (
            <Calendar
              duration={duration}
              employee={employee}
              selectedDay={selectedDay}
              dayIsSelected={dayIsSelected}
              setSelectedDay={setSelectedDay}
              setSelectedDateTime={setSelectedDateTime}
              setDayIsSelected={setDayIsSelected}
              nextStep={nextStep}
              service={service}
            />
          )}
          {!dayIsSelected && (
            <BottomActionBar secondaryText="Back" secondaryAction={goBack} />
          )}
        </Box>
      );
    case 'YOUR_INFO':
      return <PersonalInfo nextStep={nextStep} prevStep={goBack} />;

    case 'CONFIRM':
      return (
        <Box>
          {employee && (
            <ConfirmPiercing
              service={service}
              employee={employee}
              selectedDateTime={selectedDateTime}
              duration={duration}
              price={price}
              prevStep={prevStep}
              backToStep={backToStep}
            />
          )}
        </Box>
      );

    default:
      return (
        <PageError
          message="There was an error loading this artist's page. Please double
        check that you've entered the url in correctly, and if the error
        persists, reach out to our support team using the help icon below."
        />
      );
  }
}
