import React from 'react';
import { Box, TextField, OutlinedInput, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import InputWrapper from '../InputWrapper';
import { important } from '../../../../utils/global';

interface BirthdayInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  useTextField?: boolean;
}

function BirthdayInput({
  name,
  label,
  disabled,
  useTextField,
}: BirthdayInputProps) {
  const formik = useFormikContext();
  const theme = useTheme();

  const inputStyles = {
    height: important('44px'),
    fontSize: '0.875rem',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: important('0.5px'),
      borderColor: important(theme.palette.lightOutlineGrey.main),
    },
  };

  const formikValueDate = (formik.values as any)[name] as string;
  const initialDate = formikValueDate
    ? new Date(new Date(formikValueDate).toISOString().replace('Z', ''))
    : undefined;

  const [month, setMonth] = React.useState<string>(
    initialDate ? format(initialDate, 'MM') : '',
  );
  const [day, setDay] = React.useState<string>(
    initialDate ? format(initialDate, 'dd') : '',
  );
  const [year, setYear] = React.useState<string>(
    initialDate ? format(initialDate, 'yyyy') : '',
  );

  const setFormikValue = async (
    newMonth: string | undefined,
    newYear: string | undefined,
    newDay: string | undefined,
  ) => {
    const date =
      newMonth && newYear?.length === 4 && newDay
        ? new Date(
            parseInt(newYear, 10),
            parseInt(newMonth, 10) - 1,
            parseInt(newDay, 10),
          ).toISOString()
        : '';
    await formik.setFieldValue(name, date, true);
    formik.setFieldTouched(name, true, true);
  };

  const onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    type: 'month' | 'day' | 'year',
  ) => {
    if (event.target.value.length === 1 && type !== 'year') {
      const newValue = `0${event.target.value}`;
      if (type === 'month') {
        setMonth(newValue);
        setFormikValue(newValue, year, day);
      } else {
        setDay(newValue);
        setFormikValue(month, year, newValue);
      }
    }
  };

  const changeMonth = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMonth(event.target.value);
    setFormikValue(event.target.value, year, day);
  };

  const changeYear = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setYear(event.target.value);
    setFormikValue(month, event.target.value, day);
  };

  const changeDay = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDay(event.target.value);
    setFormikValue(month, year, event.target.value);
  };

  const InputComponent = useTextField ? TextField : OutlinedInput;

  return (
    <InputWrapper label={label} name={name}>
      <Box
        display="flex"
        flexDirection="row"
        gap={0}
        width="100%"
        sx={{ pt: useTextField ? 1 : 0 }}>
        <InputComponent
          id="month"
          name="month"
          className="month-input"
          disabled={disabled}
          type="text"
          placeholder={useTextField ? '' : 'MM'}
          value={month}
          onChange={changeMonth}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 2,
          }}
          sx={!useTextField ? inputStyles : {}}
          onBlur={event => onBlur(event, 'month')}
          {...(useTextField && {
            label: 'MM',
          })}
        />
        <InputComponent
          id="day"
          name="day"
          className="day-input"
          disabled={disabled}
          type="text"
          placeholder={useTextField ? '' : 'DD'}
          value={day}
          onChange={changeDay}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 2,
          }}
          sx={!useTextField ? inputStyles : {}}
          onBlur={event => onBlur(event, 'day')}
          {...(useTextField && {
            label: 'DD',
          })}
        />
        <InputComponent
          id="year"
          name="year"
          className="year-input"
          disabled={disabled}
          type="text"
          placeholder={useTextField ? '' : 'YYYY'}
          value={year}
          onChange={changeYear}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            maxLength: 4,
            minLength: 4,
          }}
          sx={!useTextField ? inputStyles : {}}
          onBlur={event => onBlur(event, 'year')}
          {...(useTextField && {
            label: 'YYYY',
          })}
        />
      </Box>
    </InputWrapper>
  );
}

export default BirthdayInput;
